// @ts-nocheck

import { Vector2, Vector3, Matrix } from "@babylonjs/core/Maths/math.vector";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import { Space } from "@babylonjs/core/Maths/math.axis";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { EasingFunction, CubicEase, ExponentialEase } from "@babylonjs/core/Animations/easing";
import { SolidParticleSystem } from "@babylonjs/core/Particles/solidParticleSystem";
import { Effect } from "@babylonjs/core/Materials/effect";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial";
import { ShaderMaterial } from "@babylonjs/core/Materials/shaderMaterial";
import { Animation } from "@babylonjs/core/Animations/animation";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ViewerConfig } from "../../viewer";
import { AnimationSet, FocusAnimationSet } from "../graphics/animation";
import { Text3D } from "../component/annotation";
import { postProcess } from "./postprocess";
import { State } from "../component/state";
import { Interaction, Model } from "../component/model";
import { refreshBounding } from "../utility/refreshBounding";
import { glow } from "./shaders/glow";

const PI05 = Math.PI * 0.5;

export async function main(viewer: ViewerConfig) {
  const { scene, camera, models } = viewer;
  const ui = viewer.metadata.ui;

  ui.updateEnableTv(viewer.device.isMobile);

  /** Objects **/

  const textMat = new PBRMaterial('textMat', scene);
  textMat.unfreeze();
  textMat.metallic = 0.7;
  textMat.roughness = 0.2;
  textMat.albedoColor = Color3.BlackReadOnly;
  textMat.backFaceCulling = false;

  const fragments = MeshBuilder.CreateBox("fragments", { size: 1 }, scene);
  const fragmentMaterial = new PBRMaterial("fragmentMaterial", scene);
  fragmentMaterial.metallic = 0;
  fragmentMaterial.roughness = 0;
  fragmentMaterial.albedoColor = Color3.White();
  fragmentMaterial.wireframe = true;

  const speed = 1.5;
  const gravity = -0.01;

  (() => {
    const bodyMats = [];
    const jointMats = [];

    let tempMeshes;
    Object.values(viewer.metadata.sections)
      .forEach(section => {
        tempMeshes = models[section.model].getChildMeshes(false);
        bodyMats.push(tempMeshes
          .find(mesh => {
            if (!mesh?.material) return false
            return mesh.material.name === 'Alpha_Body_MAT'
          }).material);
        jointMats.push(tempMeshes
          .find(mesh => {
            if (!mesh?.material) return false
            return mesh.material.name === 'Alpha_Joints_MAT'
          }).material);
      });

    const chairMats = scene.materials.filter(material => material.name === 'lofi_chair_base');

    const bodyOriginal = bodyMats[0].clone();
    const jointsOriginal = jointMats[0].clone();
    const chairsOriginal = chairMats[0].clone();

    const WHITE3 = new Color3(0.5, 0.5, 0.5);
    const chairColor = new Color3(0.1, 0.4, 0.5);

    const colorEase = new CubicEase();
    const colorAnimation = new AnimationSet(scene, 'textAnimation', [
      {
        name: 'color',
        target: textMat,
        targetProperty: 'albedoColor',
        dataType: Animation.ANIMATIONTYPE_COLOR3,
        easingFunction: colorEase,
        easingMode: EasingFunction.EASINGMODE_EASEINOUT,
        keys: _ => [
          { frame: 0, value: textMat.albedoColor },
          { frame: 20, value: viewer.metadata.darkMode ? WHITE3 : Color3.BlackReadOnly },
        ]
      },
      // {
      //     name: 'particleColor',
      //     target: particleSystem,
      //     targetProperty: 'color1',
      //     dataType: Animation.ANIMATIONTYPE_COLOR4,
      //     easingFunction: colorEase,
      //     easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      //     keys: _ => [
      //         {frame: 0, value: particleSystem.color1},
      //         {frame: 20, value: viewer.metadata.darkMode ? WHITE4 : BLACK4},
      //     ]
      // },
    ].concat(bodyMats.map((material, i) => ({
      name: 'bodyColor' + i,
      target: material,
      targetProperty: 'albedoColor',
      dataType: Animation.ANIMATIONTYPE_COLOR3,
      easingFunction: colorEase,
      easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      keys: _ => [
        { frame: 0, value: material.albedoColor },
        { frame: 10 + i * 5, value: material.albedoColor },
        {
          frame: 30 + i * 5, value: viewer.metadata.darkMode
            ? bodyOriginal.albedoColor
            : Color3.BlackReadOnly
        },
      ]
    }))).concat(bodyMats.map((material, i) => ({
      name: 'bodyMetallic' + i,
      target: material,
      targetProperty: 'metallic',
      dataType: Animation.ANIMATIONTYPE_FLOAT,
      easingFunction: colorEase,
      easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      keys: _ => [
        { frame: 0, value: material.metallic },
        { frame: 10 + i * 5, value: material.metallic },
        {
          frame: 30 + i * 5, value: viewer.metadata.darkMode
            ? bodyOriginal.metallic
            : 0.7
        },
      ]
    }))).concat(bodyMats.map((material, i) => ({
      name: 'bodyRoughness' + i,
      target: material,
      targetProperty: 'roughness',
      dataType: Animation.ANIMATIONTYPE_FLOAT,
      easingFunction: colorEase,
      easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      keys: _ => [
        { frame: 0, value: material.roughness },
        // {frame: 10, value: material.roughness},
        {
          frame: 20, value: viewer.metadata.darkMode
            ? bodyOriginal.roughness
            : 0.2
        },
      ]
    }))).concat(jointMats.map((material, i) => ({
      name: 'jointsColor' + i,
      target: material,
      targetProperty: 'albedoColor',
      dataType: Animation.ANIMATIONTYPE_COLOR3,
      easingFunction: colorEase,
      easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      keys: _ => [
        { frame: 0, value: material.albedoColor },
        { frame: 10 + i * 5, value: material.albedoColor },
        {
          frame: 30 + i * 5, value: viewer.metadata.darkMode
            ? jointsOriginal.albedoColor
            : WHITE3
        },
      ]
    }))).concat(chairMats.map((material, i) => ({
      name: 'chairColor' + i,
      target: material,
      targetProperty: 'albedoColor',
      dataType: Animation.ANIMATIONTYPE_COLOR3,
      easingFunction: colorEase,
      easingMode: EasingFunction.EASINGMODE_EASEINOUT,
      keys: _ => [
        { frame: 0, value: material.albedoColor },
        {
          frame: 30, value: viewer.metadata.darkMode
            ? chairsOriginal.albedoColor
            : chairColor
        },
      ]
    }))));

    ui.colorAnimation = _ => {
      colorAnimation.stop();
      colorAnimation.play(false);
    }
    ui.colorAnimation();

    bodyMats.map(material => {
      material.unfreeze();
    });
    jointMats.map((material, i) => {
      material.unfreeze();
    });
    chairMats.map(material => {
      material.unfreeze();
      material.metallic = 0.7;
      material.roughness = 0.2;
    });
  })();

  const rightStick = models.stick.clone("rightStick");
  const studentChair = models.chair.clone("studentChair");

  (() => {
    const finger = models.engineer.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigLeftHandIndex1')[0];
    models.tablet.setParent(finger);
    models.tablet.setAbsolutePosition(models.tablet.parent.getAbsolutePosition());
    models.tablet.rotationQuaternion = null;
    models.tablet.position.set(32, 0, 8);
    models.tablet.setPivotPoint(
      models.tablet.getBoundingInfo().boundingBox.minimum,
      Space.LOCAL);
    models.tablet.rotation.set(0, -1.8, -PI05);
  })();

  (() => {
    const leftFinger = models.drummer.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigLeftHandIndex1')[0];
    const rightFinger = models.drummer.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigRightHandIndex1')[0];
    const leftStick = models.stick;
    leftStick.scaling.y = 0.12;

    leftStick.setParent(leftFinger);
    leftStick.setAbsolutePosition(leftStick.parent.getAbsolutePosition());
    leftStick.rotationQuaternion = null;
    leftStick.position.set(0, -1, -10);
    leftStick.setPivotPoint(
      leftStick.getBoundingInfo().boundingBox.minimum,
      Space.LOCAL);
    leftStick.rotation.set(0, -1.2, -PI05);

    rightStick.setParent(rightFinger);
    rightStick.setAbsolutePosition(rightStick.parent.getAbsolutePosition());
    rightStick.rotationQuaternion = null;
    rightStick.position.set(0, -3, -10);
    rightStick.setPivotPoint(
      rightStick.getBoundingInfo().boundingBox.minimum,
      Space.LOCAL);
    rightStick.rotation.set(0, 1.3, 1.4);
  })();

  (() => {
    viewer.models.dancer.animations.SwingDancing.speedRatio = 0.9
  })();

  Effect.ShadersStore["deskVertexShader"] = glow.vertex;
  Effect.ShadersStore["deskFragmentShader"] = glow.fragment;

  const deskMaterial = new ShaderMaterial("deskMaterial", scene, {
    vertex: "desk",
    fragment: "desk",
  },
    {
      attributes: ["position", "normal", "uv"],
      uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "time"],
      needAlphaBlending: true
    });
  scene.registerBeforeRender(() => {
    deskMaterial.setFloat('time', performance.now() * 0.001)
  });

  deskMaterial.backFaceCulling = false;

  (() => {
    const fingers = models.coder.getChildTransformNodes(false)
      .filter(node => node.name.includes('4'));

    const hips = models.coder.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigHips')[0];
    models.chair.setParent(hips);
    models.chair.setAbsolutePosition(models.chair.parent.getAbsolutePosition());
    models.chair.rotationQuaternion = null;
    models.chair.position.set(0, -240, 25);
    models.chair.setPivotPoint(
      models.chair.getBoundingInfo().boundingBox.center,
      Space.LOCAL);
    models.chair.rotation.set(0.1, 0, 0);

    const keyboard = MeshBuilder.CreateBox("desk", { width: 75, height: 40, depth: 1 }, scene);
    keyboard.material = deskMaterial;

    keyboard.parent = models.coder;
    keyboard.position.set(-5, -22, 60);
    keyboard.rotation.set(1.2, 0, 0);

    const SPS = new SolidParticleSystem('engFragments', scene);
    SPS.addShape(fragments, 25);
    const mesh = SPS.buildMesh();
    mesh.material = fragmentMaterial;

    // init
    SPS.initParticles = () => {
      for (let p = 0; p < SPS.nbParticles; p++)
        SPS.recycleParticle(SPS.particles[p]);
    };

    // recycle
    SPS.recycleParticle = particle => {
      particle.position.set(Math.random() * 50, 0, Math.random() * 50);

      particle.velocity.x = (Math.random() - 0.5) * speed;
      particle.velocity.y = Math.random() * speed;
      particle.velocity.z = (Math.random() - 0.5) * speed;

      particle.scale.x = Math.random() + 0.5;
      particle.scale.y = particle.scale.x;
      particle.scale.z = particle.scale.x;

      particle.rotation.set(Math.random() * 3.5, Math.random() * 3.5, Math.random() * 3.5);

      particle.fadeTime = 2000 + Math.random() * 500;
      particle.timePassed = 0;
      particle.color.a = 1;
    };

    let sign;
    let deltaTime = 0;

    SPS.updateParticle = particle => {
      if (particle.position.length() > 200)
        SPS.recycleParticle(particle);

      particle.timePassed += deltaTime;
      particle.fractionTime = 1 - particle.timePassed / particle.fadeTime;
      particle.color.a = particle.fractionTime;
      particle.color.r = 0;
      particle.color.g = particle.fractionTime;
      particle.color.b = particle.fractionTime;

      particle.scaling.set(particle.fractionTime, particle.fractionTime, particle.fractionTime);

      particle.position.x += particle.velocity.x;
      particle.position.y += particle.velocity.y;
      particle.velocity.z -= gravity;
      particle.position.z += speed;

      sign = (particle.idx % 2 == 0) ? 1 : -1;
      particle.rotation.z += 0.1 * sign;
      particle.rotation.x += 0.05 * sign;
      particle.rotation.y += 0.008 * sign;
    };

    SPS.initParticles();
    SPS.setParticles();
    SPS.mesh.hasVertexAlpha = true;
    SPS.mesh.parent = models.coder;
    SPS.mesh.position.set(-20, -25, 50);
    SPS.mesh.rotation.set(0, 0, 0);

    scene.registerBeforeRender(() => {
      deltaTime = viewer.engine.getDeltaTime();
      SPS.setParticles();
    });

  })();

  (() => {
    const finger = models.student.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigRightHandIndex3')[0];

    const palm = models.student.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigLeftHand')[0];

    const desk = MeshBuilder.CreateBox("desk", { width: 2, height: 2, depth: 0.1 }, scene);
    desk.material = deskMaterial;

    desk.setParent(palm);
    desk.setAbsolutePosition(desk.parent.getAbsolutePosition());
    desk.rotationQuaternion = null;
    desk.position.set(20, -9, 9);
    desk.rotation.set(1.9, 0.2, PI05);

    const hips = models.student.getChildTransformNodes(false)
      .filter(node => node.name === 'mixamorigHips')[0];
    studentChair.setParent(hips);
    // studentChair.scaling.set(0.2, 0.2, 0.2);
    studentChair.setAbsolutePosition(studentChair.parent.getAbsolutePosition());
    studentChair.rotationQuaternion = null;
    studentChair.position.set(0, -50, 11);
    studentChair.setPivotPoint(
      studentChair.getBoundingInfo().boundingBox.center,
      Space.LOCAL);
    studentChair.rotation.set(-0.1, 0, 0);
  })();

  fragments.dispose();

  /** **/

  let postprocess;
  let enablePostprocess = false;

  if (enablePostprocess) {
    postprocess = postProcess(viewer);
    window.pp = postprocess;
  }

  let isPortrait = null;
  scene.onAfterRenderCameraObservable.addOnce(_ =>
    isPortrait = window.innerWidth / window.innerHeight < 1);

  const offset = new Vector2(-2, 6);
  const exp4ease = new ExponentialEase(4);

  const canvas = viewer.engine.getRenderingCanvas();
  let pick, hovered;
  const hoverCheck = () => {
    if (!viewer.device.supportsHover) return;
    if (State.Active !== 'main') {
      canvas.classList.remove('pointer');
      return;
    }
    pick = scene.pick(scene.pointerX, scene.pointerY);
    if (pick.hit) {
      hovered = true;
      canvas.classList.add('pointer');
    } else {
      hovered = false;
      canvas.classList.remove('pointer');
    }
  }

  const states = {
    main: new State('main', _ => {
      ui.showSection(null);
      camera.upperAlphaLimit = null;
      camera.lowerAlphaLimit = null;
      camera.upperRadiusLimit = 75;

      camera.useAutoRotationBehavior = !isPortrait;
      states.main.animation.play(false);
      scene.registerBeforeRender(hoverCheck);
    }, {
      animation: new FocusAnimationSet(scene, camera, {
        name: 'mainFocus',
        targetMesh: models.drummer,
        location: Vector3.ZeroReadOnly,
        radius: 75,
        alpha: 0,
        beta: isPortrait ? 0.01 : 0.62,
        // beta: 0.01,
        easingFunction: exp4ease,
        easingMode: EasingFunction.EASINGMODE_EASEINOUT,
        additionalAnimations: [
          {
            name: 'mainOffsetX',
            target: camera.targetScreenOffset,
            targetProperty: 'x',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            keys: _ => [
              { frame: 0, value: _ => camera.targetScreenOffset.x },
              { frame: 60, value: 0 }
            ]
          },
          {
            name: 'mainOffsetY',
            target: camera.targetScreenOffset,
            targetProperty: 'y',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            keys: _ => [
              { frame: 0, value: _ => camera.targetScreenOffset.y },
              { frame: 60, value: _ => isPortrait ? 0 : 2.5 }
            ]
          },
          {
            name: 'mainLoBetaLim',
            target: camera,
            targetProperty: 'lowerBetaLimit',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            // easingFunction: this?.easingFunction,
            // easingMode: this?.easingMode,
            keys: _ => [
              {
                frame: 0, value: _ => camera?.lowerBetaLimit
                  ? camera.lowerBetaLimit
                  : camera.beta
              },
              { frame: 60, value: _ => isPortrait ? 0.01 : 0.62 }
            ]
          },
          {
            name: 'mainUpBetaLim',
            target: camera,
            targetProperty: 'upperBetaLimit',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            // easingFunction: this?.easingFunction,
            // easingMode: this?.easingMode,
            keys: _ => [
              {
                frame: 0, value: _ => camera?.upperBetaLimit
                  ? camera.upperBetaLimit
                  : camera.beta
              },
              { frame: 60, value: _ => isPortrait ? 0.01 : 0.62 }
            ]
          },
        ]
      }, _ => {
        camera.upperAlphaLimit = isPortrait ? 0 : null;
        camera.lowerAlphaLimit = isPortrait ? 0 : null;
        camera.upperBetaLimit = isPortrait ? 0.01 : 0.62;
        camera.lowerBetaLimit = isPortrait ? 0.01 : 0.62;

        ui.showHeading(true);

        Object.values(states).forEach(state => {
          if (state.name === 'main') return;
          state.metadata.modelBox.clickInteraction.register();
          state.metadata.textBox.clickInteraction.register();
        })
      })
    }),
  };

  let lowerKey, halfLength;
  const positionModels = keys => {
    keys.forEach((key, i) => {
      const model = models[key];
      model.rotationQuaternion = null;

      if (isPortrait) {
        lowerKey = i < keys.length / 2;
        halfLength = ((keys.length - 1) / 2).toFixed();
        model.rotation.y = lowerKey ? Math.PI : 0;
        model.position.x = ((lowerKey
          ? (halfLength - i) * 2//i * 2
          : (i - halfLength) * 2 - 1) -
          ((keys.length - 1) / (keys.length % 2 ? 2 : 4))) * 10;
        model.position.z = lowerKey ? -10 : 10;
      } else {
        model.rotation.y = 2 * Math.PI * i / keys.length - 2.8274;
        model.position.x = Math.sin(model.rotation.y) * 20 *
          viewer.metadata.offsets[model.key].model;
        model.position.z = Math.cos(model.rotation.y) * 20 *
          viewer.metadata.offsets[model.key].model;
      }
      refreshBounding(model);
    });
  }

  const positionText = (model, text) => {
    text.mesh.position.y = model.position.y - 90;
    if (isPortrait) {
      text.mesh.position.x = model.position.x + viewer.metadata.offsets[model.key].text.y;
      text.mesh.position.z = model.position.z + 100;
    } else {
      text.mesh.position.x = model.position.x + 150;
      text.mesh.position.z = model.position.z + viewer.metadata.offsets[model.key].text.x;
    }
    text.mesh.rotation.x = 3.5;
  }

  const boxMat = new StandardMaterial('boxMat', scene);
  boxMat.alpha = 0;

  Object.entries(viewer.metadata.sections).forEach((entry, i) => {
    const model = models[entry[1].model];

    model.animations[entry[1].animation].play(true);

    const string = entry[1].title.split('\n');
    const text = new Text3D(
      viewer.fonts.raleway,
      string[0].toUpperCase(),
      { material: textMat, size: 30, depth: 2 },
      scene);

    if (string[1]) {
      const text2 = new Text3D(
        viewer.fonts.raleway,
        string[1].toUpperCase(),
        { material: textMat, size: 30, depth: 2 },
        scene);
      text2.mesh.parent = text.mesh;
      text2.mesh.position.z -= 30;
      text.mesh = Mesh.MergeMeshes([text.mesh, text2.mesh]);
    }

    text.mesh.setPivotMatrix(Matrix.Translation(
      -text.mesh._boundingInfo.boundingBox.centerWorld.x,
      -text.mesh._boundingInfo.boundingBox.centerWorld.y,
      -text.mesh._boundingInfo.boundingBox.centerWorld.z), false);

    text.mesh.parent = model;

    const modelBox = MeshBuilder.CreateBox(entry[0] + 'ModelBox', {
      width: 100,
      height: 200,
      depth: 200
    }, scene) as Model;
    modelBox.parent = model;
    modelBox.material = boxMat;

    const textBox = MeshBuilder.CreateBox(entry[0] + 'TextBox', {
      width: text.mesh._boundingInfo.boundingBox.extendSizeWorld.x * 2 + 1,
      height: text.mesh._boundingInfo.boundingBox.extendSizeWorld.y * 2 + 1,
      depth: text.mesh._boundingInfo.boundingBox.extendSizeWorld.z * 2 + 1
    }, scene) as Model;
    textBox.parent = text.mesh;
    textBox.material = boxMat;

    textBox.setPivotMatrix(Matrix.Translation(
      text.mesh._boundingInfo.boundingBox.centerWorld.x,
      text.mesh._boundingInfo.boundingBox.centerWorld.y,
      text.mesh._boundingInfo.boundingBox.centerWorld.z), false);

    states[entry[0]] = new State(entry[0], _ => {
      scene.unregisterBeforeRender(updateFrame);

      camera.upperAlphaLimit = null;
      camera.lowerAlphaLimit = null;

      camera.useAutoRotationBehavior = false;

      if (entry[0].name !== 'main') ui.showHeading(false);

      Object.values(states).forEach(state => {
        if (state.name === 'main') return;
        state.metadata.modelBox.clickInteraction.unregister();
        state.metadata.textBox.clickInteraction.unregister();
      })

      sectionHeight = null;
      ui.showSection('');
      ui.nextFunction = null;
      ui.previousFunction = null;
      states[entry[0]].animation.play(false, _ => {
        ui.showSection(entry[0]);
        scene.registerBeforeRender(updateFrame);
        ui.previousFunction = _ => states[Object.keys(viewer.metadata.sections)
        [(i - 1 + Object.keys(viewer.metadata.sections).length)
          % Object.keys(viewer.metadata.sections).length]].begin();
        ui.nextFunction = _ => states[Object.keys(viewer.metadata.sections)
        [(i + 1) % Object.keys(viewer.metadata.sections).length]].begin();
        sectionHeight = null;
      });
    }, {
      animation: new FocusAnimationSet(scene, camera, {
        name: entry[0] + 'Transition',
        targetMesh: model.center,
        beta: Math.PI * 0.45,
        alpha: Math.PI * 1.5 - model.rotation.y,
        radius: isPortrait ? 30 : 16,
        easingFunction: exp4ease,
        easingMode: EasingFunction.EASINGMODE_EASEINOUT,
        additionalAnimations: [
          {
            name: model.name + 'OffsetX',
            target: camera.targetScreenOffset,
            targetProperty: 'x',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            keys: _ => [
              { frame: 0, value: _ => camera.targetScreenOffset.x },
              { frame: 60, value: _ => isPortrait ? 0 : offset.x }
            ]
          },
          {
            name: model.name + 'OffsetY',
            target: camera.targetScreenOffset,
            targetProperty: 'y',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            keys: _ => [
              { frame: 0, value: _ => camera.targetScreenOffset.y },
              { frame: 60, value: _ => isPortrait ? offset.y : 0 }
            ]
          },
          {
            name: model.name + 'LoBetaLim',
            target: camera,
            targetProperty: 'lowerBetaLimit',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            // easingFunction: this?.easingFunction,
            // easingMode: this?.easingMode,
            keys: _ => [
              {
                frame: 0, value: _ => camera?.lowerBetaLimit
                  ? camera.lowerBetaLimit
                  : camera.beta
              },
              { frame: 60, value: 0.9 }
            ]
          },
          {
            name: model.name + 'UpBetaLim',
            target: camera,
            targetProperty: 'upperBetaLimit',
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            // easingFunction: this?.easingFunction,
            // easingMode: this?.easingMode,
            keys: _ => [
              {
                frame: 0, value: _ => camera?.upperBetaLimit
                  ? camera.upperBetaLimit
                  : camera.beta
              },
              { frame: 60, value: 1.9 }
            ]
          },
        ]
      }, _ => {
        camera.upperAlphaLimit = camera.alpha + Math.PI / 4;
        camera.lowerAlphaLimit = camera.alpha - Math.PI / 4;
      }),
      metadata: { model, text, modelBox, textBox }
    });

    model.getChildMeshes(false).concat(model).forEach(mesh => mesh.isPickable = false);
    modelBox.clickInteraction = new Interaction(scene, modelBox,
      ActionManager.OnLeftPickTrigger,
      _ => states[entry[0]].begin(), false);
    textBox.clickInteraction = new Interaction(scene, textBox,
      ActionManager.OnLeftPickTrigger,
      _ => states[entry[0]].begin(), false);
  });

  viewer.metadata.states = states;

  function updatePostProcess() {
    if (!enablePostprocess) return;
    if (isPortrait) {
      postprocess.vignetteWeight = 4;
      postprocess.vignetteStretch = 1;
    } else {
      postprocess.vignetteWeight = 1;
      postprocess.vignetteStretch = 0;
    }
  }

  let top, bottom, width, height;
  let sectionHeight = null;
  let stm = scene.getTransformMatrix();
  let vp = camera.viewport.toGlobal(window.innerWidth, window.innerHeight);

  let previousOffset = new Vector2();

  function fitScreen() {
    width = Math.min(window.innerWidth, window.outerWidth);
    height = Math.min(window.innerHeight, window.outerHeight);
    document.documentElement.style.setProperty('--width', width + 'px');
    document.documentElement.style.setProperty('--height', height + 'px');
    vp = camera.viewport.toGlobal(width, height);

    if (isPortrait !== window.innerWidth / window.innerHeight < 1) {
      isPortrait = window.innerWidth / window.innerHeight < 1;
      camera.useAutoRotationBehavior = !isPortrait;
      ui.updateUIClass(isPortrait);
      updatePostProcess(isPortrait);

      previousOffset = camera.targetScreenOffset.clone();
      camera.targetScreenOffset.x = isPortrait ? 0 : (previousOffset.y !== 0 ? offset.x : 0);
      camera.targetScreenOffset.y = isPortrait ? (previousOffset.x !== 0 ? offset.y : 0) : 0;

      if (State.Active && State.Active !== 'main') {
        if (isPortrait) {
          camera.radius = 30;
          camera.upperRadiusLimit = 36;
          camera.lowerRadiusLimit = 24;
        } else {
          camera.radius = 16;
          camera.upperRadiusLimit = 19.2;
          camera.lowerRadiusLimit = 12.8;
        }
      }

      positionModels(Object.values(viewer.metadata.sections).map(section => section.model));
      Object.values(states).forEach(state => {
        if (state.name === 'main') {
          state.animation.beta = isPortrait ? 0.01 : 0.62;
        } else {
          state.animation.radius = isPortrait ? 30 : 16;
          state.animation.alpha = isPortrait
            ? state.metadata.model.rotation.y - PI05
            : Math.PI * 1.5 - state.metadata.model.rotation.y;
          positionText(state.metadata.model, state.metadata.text);
        }
        state.animation._animations &&
          state.animation.setup(
            state.animation.animations.concat(state.animation.additionalAnimations));
      });
      State.Active && states[State.Active].begin();
    }

    viewer.engine.resize();
  }
  window.addEventListener('resize', fitScreen);
  fitScreen();

  const updateFrame = _ => {
    width = Math.min(window.innerWidth, window.outerWidth);
    height = Math.min(window.innerHeight, window.outerHeight);
    document.documentElement.style.setProperty('--width', width + 'px');
    document.documentElement.style.setProperty('--height', height + 'px');
    vp = camera.viewport.toGlobal(width, height);
    if (State.Active === 'main') return;
    if (isPortrait) {
      bottom = Vector3.Project(
        states[State.Active].metadata.text.mesh._boundingInfo.boundingBox.centerWorld.add(
          Vector3.UpReadOnly.scale(0)),
        Matrix.IdentityReadOnly, stm, vp);
      ui.updateSectionStyle({
        x: 10,
        y: bottom.y,
        width: width - 20,
        height: height - bottom.y - 10
      });
      sectionHeight = null;
    } else {
      top = Vector3.Project(
        states[State.Active].metadata.text.mesh._boundingInfo.boundingBox.centerWorld.add(
          Vector3.UpReadOnly.scale(10)),
        Matrix.IdentityReadOnly, stm, vp);
      bottom = Vector3.Project(
        states[State.Active].metadata.text.mesh._boundingInfo.boundingBox.centerWorld.add(
          Vector3.UpReadOnly.scale(1)),
        Matrix.IdentityReadOnly, stm, vp);
      if (sectionHeight === null) sectionHeight = bottom.y - top.y;
      ui.updateSectionStyle({
        x: top.x,
        y: Math.max(bottom.y - sectionHeight, 10),
        width: undefined,
        height: sectionHeight,
        transform: 'translateX(-50%)'
      });
    }
  }

  states.main.begin();
}