import React, { useContext, useState, useEffect } from 'react';

export let TvContext = React.createContext();
export function useTv() { return useContext(TvContext); }

export function TvProvider({ children }) {
  const [tvEnabled, setTvEnabled] = useState(false);
  const [tvOn, setTvOn] = useState(false);
  const [played, setPlayed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [videoId, setVideoId] = useState(0);

  return (
    <TvContext.Provider value={[
      tvEnabled, setTvEnabled,
      tvOn, setTvOn,
      played, setPlayed,
      hovered, setHovered,
      videoId, setVideoId
    ]}>
      {children}
    </TvContext.Provider>
  )
}
