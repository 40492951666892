import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";

const programs = [
  {
    level: "Minor Program",
    title: 'Mechatronics',
    time: 'Sep 2012 - Jun 2015',
    courses: [
      'Mechanical Design I-II',
      'Theory of Machines',
      'Technical Drawing',
      'Computer Aided Technical Drawing',
      'Hydraulic and Pneumatic Circuits'
    ],
    image: 'assets/images/rank1.png'
  },
  {
    level: "Major Program",
    title: 'Control and Automation Engineering',
    time: 'Aug 2010 - Jun 2015',
    thesis: (<>
      <br />
      <a
        href="https://www.sae.org/publications/technical-papers/content/2015-24-2547/"
        target="_blank"
      >
        A New Control Scheme for Automated Manual Transmission with Electromagnetic Powder Clutch
      </a>
      <br />
      <span>Presented personally at Society of Automotive Engineers'
        International Conference on Engines and Vehicles; 2015, Capri, Italy.</span>
    </>),
    courses: [
      'Control System Design',
      'Robot Control',
      'Signals and Systems',
      'Feedback Control Systems',
      'Industrial Applications of Power Electronics',
      'Microcontroller System Design'
    ],
    image: 'assets/images/rank2.png'
  },
  {
    level: "Master's Program",
    title: "Mechatronics",
    time: 'Sep 2017 - Dec 2019',
    thesis: 'Modeling and Control of Complex System Dynamics Using Machine Learning',
    courses: [
      'Machine Vision',
      'Mechatronic System Design',
      'Mechatronic System Components',
      'Spacecraft Control Systems'
    ],
    image: 'assets/images/rank3.png'
  },
];

export default function Education({ style }) {
  const [progId, setProgId] = useState(1);

  const flash = useAnimation();
  useEffect(() => {
    flash.start(t => ({ opacity: [0, 1] }));
  }, []);

  return (
    <>
      <div id="ranks">
        {programs.map((program, i) =>
          <motion.button
            key={i}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              if (i !== progId)
                flash.start(t => ({ opacity: [0, 1] }));
              setProgId(i);
            }}
          >
            <img className={`blur${progId === i ? ' active' : ''}`} src={program.image} />
            <img src={program.image} />
            {progId === i && <p>{programs[i].level.toUpperCase()}</p>}
          </motion.button>)}
      </div>
      <motion.div
        id="education"
        className="section-content"
        initial={{ opacity: 0 }}
        animate={flash}
      >
        <h2>{programs[progId].title}</h2>
        <p>{programs[progId].time}</p>
        {programs[progId]?.thesis && <p>Thesis: {programs[progId].thesis}</p>}
        <p>Some Courses:</p>
        <ul>
          {programs[progId].courses.map((course, i) =>
            <li key={i}>{course}</li>)}
        </ul>
      </motion.div>
      <div style={{
        position: 'absolute',
        top: 0,
        width: 'calc(100% - 2rem)',
        height: '100%',
        backgroundImage: 'url("assets/images/itu.png")',
        backgroundSize: '75%',
        backgroundPositionX: 'right',
        backgroundPositionY: 'calc(100% - 1rem)',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'none'
      }}></div>
      <p id="itu">
        {'* Istanbul Technical University is '}
        <a
          target="_blank"
          href="https://www.timeshighereducation.com/world-university-rankings/istanbul-technical-university">
          the third oldest
        </a>
        {' technical university in the world.'}
      </p>
    </>
  );
}