import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Player from "../components/Player";
import { useViewer } from "../../hooks/ViewerContext";
import { useTv } from "../../hooks/TvContext";

const interests = [
  { title: 'drums', icon: '🥁' },
  { title: 'writing', icon: '✍🏼' },
  { title: 'dancing', icon: '🕺🏻' },
  { title: 'learning', icon: '🌍' },
];

const players = [
  {
    remark: 'I play the cajón in this one',
    video: 'https://www.youtube.com/embed/f0qQ_KM2KTc?&autoplay=1',
    image: 'assets/images/bmsa_thumb.jpg',
  },
  {
    remark: 'This one is my favorite',
    video: 'https://www.youtube.com/embed/bld7y8tpiqw?&autoplay=1',
    image: 'assets/images/zd_thumb.jpg',
  },
  {
    remark: 'One of the most popular stages in Istanbul',
    video: 'https://www.youtube.com/embed/ovj1EHyzvzE?&autoplay=1',
    image: 'assets/images/bmsl_thumb.jpg',
  }
]

export default function Interests({ videos }) {
  const [hobbyKey, setHobbyKey] = useState('');
  const [thumbId, setThumbId] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const [viewer,] = useViewer();
  const [, setTvEnabled, tvOn, setTvOn, played, setPlayed, , , videoId, setVideoId] = useTv();
  const flash = useAnimation();

  useEffect(() => {
    flash.start(t => ({ opacity: [0, 1] }));
  }, []);

  return (
    <>
      <div id="icons">
        <AnimatePresence>
          {interests.map((hobby, i) =>
            <motion.button
              initial={{ scale: 0 }}
              animate={{ scale: initialized ? 1 : [0, 1.2, 0.8, 1] }}
              transition={{ delay: initialized ? 0 : i * 0.132 }}
              key={hobby.title}
              whileTap={{ scale: 0.9 }}
              onClick={() => setHobbyKey(hobby.title)}
              onAnimationComplete={() =>
                !initialized && i === interests.length - 1 &&
                setInitialized(true)}
            >
              {hobby.icon}
            </motion.button>)}
        </AnimatePresence>
      </div>
      <motion.div
        id="interests"
        className="section-content"
        initial={{ opacity: 0 }}
        animate={flash}
      >
        <p>
          I spend most of my time <span className={hobbyKey === 'drums' ? 'active' : ''}>playing drums, discovering new music</span>, <span
            className={hobbyKey === 'writing' ? 'active' : ''}>reading/writing fantasy stories</span>
          , <span className={hobbyKey === 'dancing' ? 'active' : ''}>dancing, skiing</span>, <span
            className={hobbyKey === 'learning' ? 'active' : ''}>learning visual design, getting lost in technology news</span> and
          playing <span className={hobbyKey === 'writing' ? 'active' : ''}>inspirational, story based video games</span>.
          I have performed <span className={hobbyKey === 'drums' ? 'active' : ''}>over 100 professional stage shows by drum playing</span> with
          various bands.
          <br />
        </p>
        <p>I also love <span className={hobbyKey === 'learning' ? 'active' : ''}>languages:</span>
        </p>
        <ul>
          <li><span className={hobbyKey === 'learning' ? 'active' : ''}>🇹🇷 Native Turkish,</span></li>
          <li><span className={hobbyKey === 'learning' ? 'active' : ''}>🇬🇧 Advanced English speaker; daily, academic and technical,</span>
          </li>
          <li>Used to be able to communicate in German, now out of practice,</li>
          <li>Can read Cyrillic and Greek, but cannot understand,</li>
          <li>Also used to be able to read some Japanese and Korean, again, now out of practice.</li>
          {/* 🇩🇪 🇺🇦 🇬🇷 🇯🇵 🇰🇷 */}
        </ul>
        <p>Here's a couple of <span className={hobbyKey === 'drums' ? 'active' : ''}>videos from my band:</span>
        </p>
        {viewer.device.isMobile
          ? players.map((player, i) => <Player
            key={i}
            remark={player.remark + ':'}
            video={player.video}
            image={player.image} />)
          : <div className="thumbs">
            {players.map((player, i) => <>
              <button
                key={'button' + i}
                onMouseOver={() => setThumbId(i)}
                onMouseOut={() => setThumbId(null)}
                onClick={() => {
                  setPlayed(true);
                  setVideoId(i);
                  setTvEnabled(true);
                  setTvOn(true);
                }}
              >
                <img key={'img' + i} src={player.image} />
              </button>
              <AnimatePresence>{
                thumbId === i && <motion.p
                  key={'p' + i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >{player.remark}</motion.p>
              }</AnimatePresence>
            </>)}

          </div>
        }
      </motion.div>
    </>
  );
}