// @ts-nocheck

import '@babylonjs/core/Animations/animatable';
import '@babylonjs/core/Collisions/collisionCoordinator';
import '@babylonjs/core/Engines/Extensions/engine.externalTexture';
import '@babylonjs/core/Helpers/sceneHelpers';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import '@babylonjs/core/Loading/Plugins/babylonFileLoader';
import '@babylonjs/core/Materials/Node/Blocks';
import '@babylonjs/core/Materials/Textures/Loaders/ddsTextureLoader';
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';
import '@babylonjs/core/Materials/Textures/Loaders/hdrTextureLoader';
import '@babylonjs/core/Misc/screenshotTools';
import '@babylonjs/core/Rendering/depthPeelingSceneComponent';
import '@babylonjs/core/Rendering/geometryBufferRendererSceneComponent';
import '@babylonjs/core/Rendering/prePassRendererSceneComponent';
import '@babylonjs/core/Rendering/outlineRenderer';
import '@babylonjs/loaders/glTF';
import {AbstractMesh} from "@babylonjs/core/Meshes/abstractMesh";
import {ActionEvent} from "@babylonjs/core/Actions/actionEvent";
import {ActionManager} from "@babylonjs/core/Actions/actionManager";
import {AnimationGroup} from "@babylonjs/core/Animations/animationGroup";
import {BoundingInfo} from "@babylonjs/core/Culling/boundingInfo";
import {ExecuteCodeAction} from "@babylonjs/core/Actions/directActions";
import {Mesh} from "@babylonjs/core/Meshes/mesh";
import {MeshBuilder} from "@babylonjs/core/Meshes/meshBuilder";
import {Node} from "@babylonjs/core/node";
import {TransformNode} from "@babylonjs/core/Meshes/transformNode";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {SceneLoader} from "@babylonjs/core/Loading/sceneLoader";
// import {
//     AbstractMesh, ActionEvent, ActionManager,
//     AnimationGroup, BoundingInfo, ExecuteCodeAction,
//     Mesh,
//     MeshAssetTask,
//     MeshBuilder,
//     Node,
//     Scene,
//     Space,
//     TransformNode,
//     Vector3
// } from "babylonjs";
// import "babylonjs-loaders";
import {assets} from "../user/settings";
import {refreshBounding} from '../utility/refreshBounding'
import {AuxConfig} from "../system/auxiliaries";
import {OpacityAnimationSet} from "../graphics/animation";

// import {USDZExporter} from "../../lib/usdzExporter.js"
// import { zipSync, strToU8 } from '../../lib/fflate.module.min.js'
// import {exportScene} from "../utility/exportScene";

interface ModelParameterConfig {
    pathToModel: string,
    modelFile: string,
    rotation?: Vector3,
    parts?: any,
    checkCollisions?: boolean,
    showBoundingBox?: boolean,
    visible?: boolean | number,
    scale?: number,
    category?: string,
    pivotAndCenter?: boolean,
    hasBoundingBoxes?: boolean
}

export interface ProcessMeshesConfig {
    key: string,
    meshes: Mesh[],
    animationGroups: AnimationGroup[],
    animations: Object,
    rotation: Vector3,
    parts?: Object,
    checkCollisions?: boolean,
    showBoundingBox?: boolean,
    visible: boolean | number,
    scale: number,
    pivotAndCenter?: boolean,
    hasBoundingBoxes: boolean,
    category?: string,
    metadata?: Object
}

export class Model extends Mesh {
    key: string;
    center: AbstractMesh;
    update: Function;
    parts: Object;
    box: Mesh | AbstractMesh;
    actionManager: ActionManager;
    category: string;
    clickInteraction: any;
    doubleClickInteraction: any;
    upInteraction: any;
    overInteraction: any;
    outInteraction: any;
    childModels: [];
    // importedAnimations: Object;
    animations: Object;
    metadata: Object;
    meshes: any;

    aux: AuxConfig;

    // clone(name?: string, newParent?: Node, doNotCloneChildren?: boolean, clonePhysicsImpostor?: boolean): Model {
    //     let model = super.clone(name, newParent, doNotCloneChildren, clonePhysicsImpostor);
    //     console.log('b ', refreshBounding(model, true, false));
    //     console.log('m ', model)
    //
    //
    //
    //     // let model = this;
    //     //
    //     // model.update = function (world = true) {
    //     //     // model.computeWorldMatrix(true);
    //     //     model.getBoundingInfo();
    //     //     let prop = world ? 'centerWorld' : 'center';
    //     //     model.center.setAbsolutePosition(model._boundingInfo.boundingBox[prop]);
    //     // }
    //     return model;
    // }
}

export async function setupModels(
    scene: Scene,
    models: Object,
    aux: AuxConfig,
    callback: Function,
    progressFunction: Function,
    verbose?: boolean = false
) {
    if (Object.keys(assets.models).length === 0) {
        callback();
        return models;
    }

    let blockers = [];

    for (let [key, parameters] of Object.entries(assets.models)) {
        blockers.push(new Promise(resolve => resolve(
            loadModel(key, parameters, scene, models, aux, null, progressFunction, verbose))
        ));
        // task = aux.assetsManager.addMeshTask(
        //     key,
        //     '',
        //     pathToModel,
        //     parameters.modelFile
        // );
        // if (loadingBlocker) aux.blockerTasks.push(task);
        //
        // task.onSuccess = subtask => {
        //     model = processMeshes(<ProcessMeshesConfig>{
        //         key: key,
        //         meshes: subtask.loadedMeshes,
        //         animationGroups: subtask.loadedAnimationGroups,
        //         rotation: parameters['rotation'],
        //         parts: parameters['parts'],
        //         checkCollisions: parameters['checkCollisions'],
        //         showBoundingBox: parameters['showBoundingBox'],
        //         visible: parameters['visible'],
        //         scale: parameters['scale'],
        //         pivotAndCenter: parameters['pivotAndCenter'],
        //         hasBoundingBoxes: parameters['hasBoundingBoxes'],
        //         category: parameters['category']
        //     }, scene, aux.mainRoot);
        //
        //     if (parameters['category'] === undefined) models[key] = model;
        //     else {
        //         if (models.categories[parameters['category']] === undefined)
        //             models.categories[parameters['category']] = {};
        //         models.categories[parameters['category']][key] = model;
        //     }
        //
        //     aux.mainRoot.computeWorldMatrix(true);
        //     // model.parent = aux.mainRoot;
        //     // aux.assetContainer.meshes.push(model);
        // }
    }

    if (blockers.length > 0) await Promise.all(blockers);
    callback();
    verbose && console.log('LOADED');

    // return models;
}

class BoundingNode extends Node {
    _boundingInfo: BoundingInfo;
    getBoundingInfo: Function;
}

export class Interaction {
    model: Model;
    triggerOptions: any;
    action: ExecuteCodeAction;
    callback: (event: ActionEvent) => void;
    _scene: Scene

    constructor(scene, model, triggerOptions, callback, register = false, pickInvisible = true) {
        this.model = model;
        this._scene = scene;
        this.callback = callback;
        this.triggerOptions = triggerOptions;
        // let predicate = pickInvisible
        //     ? new BABYLON.Condition(model.actionManager, mesh => { return mesh.isPickable })
        //     : null
        this.action = null;
        if (register) this.register();
    }

    register() {
        if (!this.model?.actionManager) this.model.actionManager = new ActionManager(this._scene);
        this.model.actionManager.isRecursive = true;
        if (!this.action)
            this.action = new ExecuteCodeAction(this.triggerOptions, this.callback);//, predicate);
        this.model.isPickable = true;
        this.model.enablePointerMoveEvents = true;
        if (!this.model.actionManager.actions.some(action => action === this.action))
            this.model.actionManager.registerAction(this.action);
        this.model.actionManager.hoverCursor = 'pointer';
    }

    unregister() {
        if (this.model?.actionManager && this.action) {
            this.model.actionManager.unregisterAction(this.action);
            this.action?.dispose && this.action.dispose();
        }
        if (this.model?.actionManager &&
            this.model.actionManager.actions.length <= 0) {
            this.model.actionManager.hoverCursor = '';
            this.model.actionManager.dispose();
        }
    }

    assign(callback, register = true) {
        this.unregister();
        this.callback = callback;
        this.action = new ExecuteCodeAction(this.triggerOptions, this.callback);
        if (register) this.register();
    }
}

export async function processMeshes(
    config: ProcessMeshesConfig,
    scene: Scene,
    root: TransformNode,
    clone = false,
    verbose? = false)
{
    let model: Model

    model = config.meshes[0] as Model;
    // if (clone) model = config.meshes[0].createInstance(config.key + 'Clone') as Model;
    model.name = '__ROOT_' + config.key + '__';
    model.id = '__ROOT_' + config.key + '__';
    model.key = config.key;
    verbose && console.log('PROCESS:', model.key);

    model.meshes = config.meshes;
    model.category = config?.category;
    model.childModels = [];
    model.metadata = {};

    model.clickInteraction = new Interaction(scene, model, ActionManager.OnLeftPickTrigger, _ => {}, false);
    // model.doubleClickInteraction = new Interaction(scene, model, ActionManager.OnDoublePickTrigger, _ => {}, false);
    model.upInteraction = new Interaction(scene, model, ActionManager.OnPickUpTrigger, _ => {}, false);
    model.overInteraction = new Interaction(scene, model, ActionManager.OnPointerOverTrigger, _ => {}, false);
    model.outInteraction = new Interaction(scene, model, ActionManager.OnPointerOutTrigger, _ => {}, false);
    verbose && console.log('INTERACTIONS:', model.key);

    // model.importedAnimations = {};

    model.parent = root;

    model.animations = {};
    if (config?.animationGroups) {
        config.animationGroups.forEach(ag => {
            ag.stop();
            model.animations[ag.name] = ag;

            config?.animations && Object.entries(config.animations).forEach(entry => {
                if (ag.name === entry[1]) model.animations[entry[0]] = ag;
            });
            ag.name = model.key + '_' + ag.name;
            verbose && console.log('ANIMATION:', ag.name);
        });
    }

    // if (config.animations) Object.entries(config.animations).forEach(
    //
    // );

    model.center = MeshBuilder.CreateSphere("CENTER_" + config.key, {diameter: 0.01}, scene);
    model.center.visibility = 0;
    model.center.parent = model;
    model.center.isVisible = false;
    model.center.isMeta = true;

    config.meshes.forEach(mesh => {
        mesh.visibility = 'visible' in config ? Number(config.visible) : 1;
        if (!model?.box && config?.hasBoundingBoxes && mesh.name.startsWith('MAINBOX')) {
            mesh.visibility = 0;
            // mesh.isVisible = false;
            mesh.showBoundingBox = config?.showBoundingBox ? config.showBoundingBox : false;
            mesh.checkCollisions = config?.checkCollisions ? config.checkCollisions : false;
            model.box = mesh;
        }
        mesh.computeWorldMatrix(true);
        // if (!mesh.name.includes("BOX_")) mesh.doNotSyncBoundingInfo = true;
        // mesh.checkCollisions = options.checkCollisions !== undefined && options.checkCollisions;
        // mesh.showBoundingBox = options.showBoundingBox !== undefined && options.showBoundingBox;
        if (mesh.name.startsWith('BOX_')) {
            // if (!model?.box && (mesh.name === 'BOX_' + config.key)) model.box = mesh;
            // mesh.isVisible = false;
            mesh.visibility = 0;
            mesh.showBoundingBox = config?.showBoundingBox ? config.showBoundingBox : false;
            mesh.checkCollisions = config?.checkCollisions ? config.checkCollisions : false;
        }
        mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
        if (mesh?.material) mesh.material.freeze();
        // else {
        //     mesh.material = new BABYLON.Material(mesh.name + 'Mat', scene, true);
        //     mesh.material.uniqueId = Math.random() * 1000;
        // }
        verbose && console.log('PROCESS MESH:', model.key, mesh.name);
    });

    let bounding;
    model.getBoundingInfo = () => {
        bounding = refreshBounding(model, true, false);
        if (model?.center) model.center.setAbsolutePosition(bounding.boundingBox.centerWorld);
        return bounding;
    }

    model.update = function (world = true) {
        // model.computeWorldMatrix(true);
        model.getBoundingInfo();
        let prop = world ? 'centerWorld' : 'center';
        model.center.setAbsolutePosition(model._boundingInfo.boundingBox[prop]);
    }
    model.update();

    // model.computeWorldMatrix(true);
    if (config?.pivotAndCenter && config.pivotAndCenter) {
        model.setPivotPoint(model._boundingInfo.boundingBox.centerWorld, Space.WORLD);
        model.translateFromPivot = true;
        model.translate(model._boundingInfo.boundingBox.centerWorld.scale(-1), Space.WORLD);
    }

    if (config?.rotation) model.rotation = config.rotation;
    // model.update();

    // model.translate(model.getBoundingInfo().boundingBox.centerWorld.scale(-1), Space.WORLD);

    let mesh: Mesh | AbstractMesh;
    let node: BoundingNode;

    if (config?.parts) {
        for (const [key, importName] of Object.entries(config.parts)) {
            mesh = scene.getMeshByName(importName);
            node = scene.getNodeByName(importName) as BoundingNode;

            if (!model?.parts) model.parts = {};

            if (mesh !== null) model.parts[key] = mesh;
            else if (node !== null) {
                model.parts[key] = node;

                let box = scene.getMeshByName("BOX_" + importName);
                if (box) {
                    node.getBoundingInfo = (world = true) => {
                        let box = scene.getMeshByName("BOX_" + importName);
                        return box.getBoundingInfo();
                    }
                } else {
                    model.parts[key].setBoundingInfo = (boundingInfo) => model.parts[key]._boundingInfo = boundingInfo;
                    model.parts[key].getBoundingInfo = () => refreshBounding(model.parts[key], true, false, false);
                }
            }
            model.parts[key].box = scene.getMeshByName("BOX_" + importName);
            model.parts[key].hide = new OpacityAnimationSet(scene, {
                name: key + 'Hide',
                targetMesh: model.parts[key],
                value: 0
            });
            model.parts[key].show = new OpacityAnimationSet(scene, {
                name: key + 'Show',
                targetMesh: model.parts[key],
                value: 1
            });
        }
    }

    model.showBoundingBox = config?.showBoundingBox ? config.showBoundingBox : false;
    // if (config.showBoundingBox) model.showBoundingBox = true;

    // model.scaling = new Vector3(scaling, scaling, scaling);
    if (config?.scale) model.scaling = model.scaling.scale(config.scale);

    // if (Object.keys(categories).length !== 0) {
    //     let keywords = Object.values(categories);
    //     let partName, category, box;
    //     for (const searchMesh of model.getChildren(null, false)) {
    //         for (const keyword of keywords) {
    //             if (searchMesh.name.startsWith(keyword)) {
    //                 category = categories[keywords.indexOf(searchMesh.name.split('_')[0])];
    //                 partName = searchMesh.name.substring(searchMesh.name.indexOf('_') + 1);
    //                 if (!models?.categories) viewer.models.categories = {};
    //                 if (models.categories[category] === undefined) viewer.models.categories[category] = {
    //                     elements: {},
    //                     box: {}
    //                 };
    //                 searchMesh.category = category;
    //                 viewer.models.categories[category].elements[partName] = searchMesh;
    //                 viewer.models.categories[category].elements[partName].key = partName;
    //                 box = viewer.scene.getMeshByName('BOX_' + keyword);
    //                 if (box !== undefined) viewer.models.categories[category].box = box;
    //                 break;
    //             }
    //         }
    //     }
    // }

    model.computeWorldMatrix(true);
    // model.freezeWorldMatrix(Matrix.Identity());
    // var gizmoManager = new BABYLON.GizmoManager(scene);
    // gizmoManager.positionGizmoEnabled = true;
    // gizmoManager.usePointerToAttachGizmos = false;
    // gizmoManager.attachToMesh(model);
    // gizmoManager.gizmos.positionGizmo.xGizmo.dragBehavior.onDragObservable.clear();
    // gizmoManager.gizmos.positionGizmo.yGizmo.dragBehavior.onDragObservable.clear();
    // gizmoManager.gizmos.positionGizmo.zGizmo.dragBehavior.onDragObservable.clear();
    return model;
}

export async function loadModel(
    key: string,
    parameters: ModelParameterConfig,
    scene: Scene,
    models: Object,
    aux: AuxConfig,
    callback: Function,
    progressFunction: Function,
    verbose?: boolean = false
) {
    let task: MeshAssetTask;
    let pathToModel: string;
    let model: Model;
    let loadingBlocker: boolean;

    let progress: number;

    if (!parameters) return;
    pathToModel = parameters.pathToModel.endsWith('/')
        ? parameters.pathToModel
        : parameters.pathToModel + '/';

    loadingBlocker = parameters['loadingBlocker'] === undefined
        ? true
        : parameters['loadingBlocker'];

    verbose && console.log('IMPORT START')
    model = await SceneLoader.ImportMeshAsync(
        '',
        pathToModel,
        parameters.modelFile,
        scene,
        x => {
            progress = x.loaded / (x.total > 0 ? x.total : 4000000);
            try {
                progressFunction(progress);
            } catch (e) {}
            viewer._options.verbose && console.log(progress)
            // progressElement.innerText = (100 * progress).toFixed(0) + '%';
        }
    ).then(async importData => {
        verbose && console.log('IMPORT FINISH')
        const {meshes, particleSystems, skeletons, animationGroups} = importData;
        animationGroups.forEach(anim => {
            anim.loopAnimation = false;
            anim.stop();
        });
        let importedModel = await processMeshes(<ProcessMeshesConfig>{
            key: key,
            meshes: meshes,
            animationGroups: animationGroups,
            animations: parameters['animations'],
            rotation: parameters['rotation'],
            parts: parameters['parts'],
            checkCollisions: parameters['checkCollisions'],
            showBoundingBox: parameters['showBoundingBox'],
            visible: parameters['visible'],
            scale: parameters['scale'],
            pivotAndCenter: parameters['pivotAndCenter'],
            hasBoundingBoxes: parameters['hasBoundingBoxes'],
            category: parameters['category']
        }, scene, aux.mainRoot, false, verbose);

        if (parameters['category'] === undefined) models[key] = importedModel;
        else {
            if (models.categories[parameters['category']] === undefined)
                models.categories[parameters['category']] = {};
            models.categories[parameters['category']][key] = importedModel;
        }

        aux.mainRoot.computeWorldMatrix(true);
        callback && callback();

        return importedModel;
    });

    return model;
}
