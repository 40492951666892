import React, { useContext, useState, useEffect } from 'react';
// import { Viewer } from "../../viewer";
// import {Color3} from "@babylonjs/core/Maths/math.color";
// import {Color3} from "babylonjs";

export let ViewerContext = React.createContext();
export let InterfaceContext = React.createContext();

export function useViewer() { return useContext(ViewerContext); }
export function useInterface() { return useContext(InterfaceContext); }

export function ViewerProvider({ children }) {
  const [viewer, setViewer] = useState(null);
  const [opacity, setOpacity] = useState(null);
  const [detailsClass, setDetailsClass] = useState(null);
  const [roomName, setRoomName] = useState(null);

  // const [setLoading, , setRoom, setFocus, , , ] = usePageUpdate();
  // const [data, , getProduct, , ] = useData();

  // useEffect(() => {
  //     window.viewer = viewer;
  //     if (!initialized && viewer) {
  //         viewer.ui = {
  //             // updateBadge: _ => {},
  //             // showHome: _ => {},
  //             // showLoading: _ => setLoading(true),
  //             // hideLoading: _ => setLoading(false),
  //             // updateProgress: percent =>
  //             //     document.documentElement.style.setProperty('--percent', percent + '%'),
  //             // showRoomUI: _ => setRoom(true),
  //             // hideRoomUI: _ => setRoom(false),
  //             // showRoomSelector: _ => {},
  //             // showProductUI: (name, fcn = null) => {
  //             //     getProduct(name);
  //             //     setFocus(true);
  //             //     setDetailsClass('');
  //             //     if (fcn) viewer.ui.setRoomFunction(fcn);
  //             // },
  //             // hideProductUI: _ => {
  //             //     setDetailsClass('shrink');
  //             //     setFocus(false);
  //             // },
  //             // showDetails: _ => setDetailsClass(''),
  //             // hideDetails: _ => setDetailsClass('shrink'),
  //             // updateProductUI: _ => {},//setProduct(false),
  //             // setProductUIOpacity: opacity => setOpacity(opacity),
  //             // nextFunction: _ => {},
  //             // previousFunction: _ => {},
  //             // buyFunction: _ => {},
  //             // roomFunction: _ => {},
  //             // setNextFunction: fcn => setViewer((_ => {
  //             //     tempViewer = viewer;
  //             //     tempViewer.ui = {
  //             //         ...tempViewer.ui,
  //             //         nextFunction: fcn
  //             //     };
  //             //     return tempViewer;
  //             // })()),
  //             // setPreviousFunction: fcn => setViewer((_ => {
  //             //     tempViewer = viewer;
  //             //     tempViewer.ui = {
  //             //         ...tempViewer.ui,
  //             //         previousFunction: fcn
  //             //     };
  //             //     return tempViewer;
  //             // })()),
  //             // setBuyFunction: fcn => setViewer((_ => {
  //             //     tempViewer = viewer;
  //             //     tempViewer.ui = {
  //             //         ...tempViewer.ui,
  //             //         buyFunction: fcn
  //             //     };
  //             //     return tempViewer;
  //             // })()),
  //             // setRoomFunction: fcn => setViewer((_ => {
  //             //     tempViewer = viewer;
  //             //     tempViewer.ui = {
  //             //         ...tempViewer.ui,
  //             //         roomFunction: fcn
  //             //     };
  //             //     return tempViewer;
  //             // })()),
  //             // showBuy: _ => {}
  //         }
  //         // viewer.data = {
  //         //     ...errorData,
  //         //     startCounter: _ => {},
  //         // };
  //     }
  // }, [viewer]);
  //
  // useEffect(() => {
  //     if (viewer) {
  //         tempViewer = viewer;
  //         // tempViewer.data = data;
  //         setViewer(tempViewer);
  //     }
  // }, [viewer]);

  return (
    <ViewerContext.Provider value={[viewer, setViewer]}>
      <InterfaceContext.Provider
        value={[opacity, detailsClass, setDetailsClass, roomName, setRoomName]}
      >
        {children}
      </InterfaceContext.Provider>
    </ViewerContext.Provider>
  )
}
