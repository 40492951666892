// @ts-nocheck

export class State {
    public static Verbose = false;
    public static Active = '';
    public name: string;
    public _begin: Function;
    public metadata: Object;

    constructor(name, begin, config?) {
        this.name = name;
        this._begin = begin;

        if (config?.animation) this.animation = config.animation;
        if (config?.verbose) State.Verbose = true;
        this.metadata = config?.metadata ? config.metadata : {};
    }

    begin() {
        this._begin && this._begin();
        State.Verbose && console.log('STATE', this.name);
        State.Active = this.name;
    }
}