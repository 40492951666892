export const glow = {
  vertex: `
    attribute vec3 position;
    attribute vec2 uv;
    
    uniform mat4 worldViewProjection;
    
    varying vec2 vUv;

    void main()
    {
      gl_Position = worldViewProjection * vec4(position, 1.0);
      vUv = uv;
    }
  `,
  fragment: `
    uniform float time;
    varying vec2 vUv;
    
    void main() {
      gl_FragColor = vec4(0.0, 1.0, 1.0, pow(distance(vUv, vec2(0.5)), 2.0) * max(abs(sin(time)), 0.1));
    }`
}