// @ts-nocheck

import { getGPUTier } from "detect-gpu";

interface DeviceProperties {
  tier;
  isMobile;
  type;
  fps;
  gpu;
  supportsHover;
  isAndroid;
  isIOS;
  isSafari;
  isFirefox;
  isOculus;
  isIOSChrome;
  isIOSSafari;
  supportsSceneViewer;
  supportsQuickLook;
  arAnchor;
  arButton: HTMLElement;
}

export async function detectDevice() {
  let device: DeviceProperties = { ...(await getGPUTier()) };
  // Example output:
  // {
  //   "tier": 1,
  //   "isMobile": false,
  //   "type": "BENCHMARK",
  //   "fps": 21,
  //   "gpu": "intel iris graphics 6100"
  // }
  // device.arButton = document.createElement("div");
  device.supportsHover = matchMedia("(hover: hover)").matches;

  device.isAndroid = /android/i.test(navigator.userAgent);
  device.isIOS =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  device.isSafari = /Safari\//.test(navigator.userAgent);
  device.isFirefox = /firefox/i.test(navigator.userAgent);
  device.isOculus = /OculusBrowser/.test(navigator.userAgent);
  device.isIOSChrome = device.isIOS && /CriOS\//.test(navigator.userAgent);
  device.isIOSSafari = device.isIOS && device.isIOSSafari;

  device.supportsSceneViewer =
    device.isAndroid && !device.isFirefox && !device.isOculus;

  device.arAnchor = document.createElement("a");
  device.supportsQuickLook = (() => {
    // const anchor = document.createElement("a");
    return (
      device.arAnchor.relList &&
      device.arAnchor.relList.supports &&
      device.arAnchor.relList.supports("ar")
    );
  })();

  return device;
}
