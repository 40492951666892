// @ts-nocheck

import {Scene} from "@babylonjs/core/scene";
import {Mesh} from "@babylonjs/core/Meshes/mesh";
import {MeshBuilder} from "@babylonjs/core/Meshes/meshBuilder";
import {Vector3, Vector4} from "@babylonjs/core/Maths/math.vector";
import {Color4} from "@babylonjs/core/Maths/math.color";
import {StandardMaterial} from "@babylonjs/core/Materials/standardMaterial";
import * as loader from '@assemblyscript/loader';

// import {
//     Scene,
//     Mesh,
//     Sprite,
//     SpriteManager,
//     Vector3,
//     AbstractMesh,
//     StandardMaterial
// } from "babylonjs";
// import {
//     AdvancedDynamicTexture,
//     Rectangle,
//     TextBlock,
//     Button,
//     Control
// } from "babylonjs-gui";
// import {AuxConfig} from "../system/auxiliaries";
// import wasmUrl from "babylon.font/build/optimized.wasm?url";
// import {AnimationSet} from "../graphics/animation";
import { Compiler, Font, TextMeshBuilder } from 'babylon.font';
import {assets} from "../user/settings";

const earcut = require('earcut');
const opentype = require('opentype.js');

export async function setupFonts(options?, rollback?) {
    let fonts: Object = {};
    let compiler: Compiler;
    // let builder: TextMeshBuilder;

    for (const [fontName, url] of Object.entries(Object.assign(assets.fonts, options))) {
        try {
            // compiler = await Compiler.Build();
            // compiler = await Compiler.Build('./src/lib/compiler.wasm');
            // compiler = await Compiler.Build('https://ycw.github.io/Babylon.Font/build/optimized.wasm');
            // compiler = await Compiler.Build('https://rawcdn.githack.com/ycw/Babylon.Font/36e99d97360e6941d2ca17d2ac80350dbb28ed85/dist/compiler.wasm');
            compiler = await Compiler.Build('https://rawcdn.githack.com/CanerErdogan/Babylon.Font/d112345319d956731d6086238b0f76e4a519cbf2/dist/compiler.wasm');
            fonts[fontName] = await Font.Install(url, compiler, opentype);
            fonts[fontName].builder = new TextMeshBuilder({
                Scene, Mesh, MeshBuilder, Vector3, Vector4, Color4
            }, earcut);
            fonts[fontName].url = url;
        } catch (error) {
            try {
                compiler = await Compiler.Build('https://canererdogan.tech/utilities/compiler.wasm');
                fonts[fontName] = await Font.Install(url, compiler, opentype);
                fonts[fontName].builder = new TextMeshBuilder({
                    Scene, Mesh, MeshBuilder, Vector3, Vector4, Color4
                }, earcut);
                fonts[fontName].url = url;
            }
            catch (e) {
                rollback?.();
                console.error(e);
                throw Error();
            }
        }
    }
    return fonts;
}

export class Text3D {
    mesh: Mesh;
    id: string;
    size: number;
    depth: number;
    material: StandardMaterial;

    constructor(font, text, options, scene) {
        this.id = Date.now().toString();
        this.depth = 0.1;
        this.size = 1;
        // this.material: StandardMaterial;

        if (options) {
            if (options.depth !== undefined) this.depth = options.depth;
            if (options?.material) this.material = options.material;
            else {
                this.material = new StandardMaterial('mat_' + this.id, scene);
                this.material.backFaceCulling = false;
            }
            if (options?.size) this.size = options.size;
        } else {
            this.material = new StandardMaterial('mat_' + this.id, scene);
            this.material.backFaceCulling = false;
        }

        // let shapes = Font.Compile(font, text, size, 1, 0.01); // see ### Font.Compile
        // this.mesh = Font.BuildMesh(shapes, {depth: depth}, scene); // see ### Font.BuildMesh
        this.mesh = font.builder.create({font, text: text, depth: this.depth, size: this.size}, scene);
        this.mesh.name = this.id;
        this.mesh.material = this.material;
        this.mesh.isAnnotation = true;
    }
}

// interface ButtonConfig {
//     width: string,
//     height: string,
//     text?: string,
//     mesh?: Mesh | AbstractMesh,
//     position?: Vector3,
//     spriteManager?: SpriteManager,
//     spriteWidth?: number,
//     spriteHeight?: number,
// }
//
// interface AnnotationConfig {
//     name: string,
//     text: string,
//     button?: ButtonConfig,
//     autoSize?: boolean,
//     textColor?: string,
//     boxColor?: string,
//     background?: string,
//     thickness?: number,
//     fontFamily?: string,
//     fontSize?: string,
//     verticalAlignment?: string,
//     horizontalAlignment?: string,
//     offsetFactors?: [number, number],
//     animated?: boolean
// }
//
// let powerEase = new BABYLON.PowerEase();
//
// export class Annotation {
//     name: string;
//     text: string;
//     textBox: Rectangle;
//     textBlock: TextBlock;
//     textColor: string;
//     boxColor: string;
//     background: string;
//     thickness: number;
//     fontFamily: string;
//     fontSize: string;
//     anchor: Mesh | AbstractMesh;
//     button: Button;
//     sprite: Sprite;
//     _animated: boolean;
//     _hideTextAnimation: AnimationSet;
//     _showTextAnimation: AnimationSet;
//     _hideButtonAnimation: AnimationSet;
//     _showButtonAnimation: AnimationSet;
//
//     constructor(scene: Scene, aux: AuxConfig, config: AnnotationConfig) {
//         if (!aux?.advTex) {
//             aux.advTex = AdvancedDynamicTexture.CreateFullscreenUI('ViewerAnno');
//             aux.advTex.hasAlpha = true;
//
//             aux.advTex.updateSamplingMode(12);
//             aux.advTex.useSmallestIdeal = true;
//         }
//         this.name = config.name;
//
//         let autoSize = config?.autoSize ? config.autoSize : true;
//
//         this.textBox = new Rectangle(this.name + 'Rect');
//         this.textBox.isPointerBlocker = false;
//         this.textBox.color = config?.boxColor ? config.boxColor : "transparent";
//         if (config?.background) this.textBox.background = config.background;
//         if (config?.thickness) this.textBox.thickness = config.thickness;
//         if (config?.fontFamily) this.textBox.fontFamily = config.fontFamily;
//         if (config?.fontSize) this.textBox.fontSize = config.fontSize;
//
//         let verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
//         if (config?.verticalAlignment) {
//             switch (config.verticalAlignment) {
//                 case ('top'): verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP; break;
//                 case ('bottom'): verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM; break;
//                 default: break;
//             }
//         }
//         let horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
//         if (config?.horizontalAlignment) {
//             switch (config.horizontalAlignment) {
//                 case ('left'): horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT; break;
//                 case ('right'): horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT; break;
//                 default: break;
//             }
//         }
//
//         this.textBlock = new TextBlock();
//         this.textBlock.text = config.text;
//         this.textBlock.resizeToFit = true;
//         this.textBlock.isPointerBlocker = false;
//         this.textBlock.color = config?.textColor ? config.textColor : "white";
//         // this.textBlock.linkOffsetY = "60px";
//         this.textBlock.textVerticalAlignment = verticalAlignment;
//         this.textBlock.textHorizontalAlignment = horizontalAlignment;
//
//         const factorX = config?.offsetFactors ? config.offsetFactors[0] : 0.6;
//         const factorY = config?.offsetFactors ? config.offsetFactors[1] : 0.75;
//         let margin = 20;
//         if (autoSize) scene.onAfterRenderObservable.addOnce(_ => {
//             this.textBox.widthInPixels = this.textBlock.widthInPixels + margin;
//             this.textBox.heightInPixels = this.textBlock.heightInPixels + margin;
//             this.textBox.linkOffsetX = this.textBox.widthInPixels * factorX;
//             this.textBox.linkOffsetY = this.textBox.heightInPixels * factorY;
//         });
//
//         if (config?.button) {
//             this.anchor = new AbstractMesh(config.name + 'Anchor', scene);
//             if (config.button?.mesh) this.anchor.setParent(config.button.mesh);
//             if (config.button?.position) this.anchor.setAbsolutePosition(config.button.position);
//             else this.anchor.setAbsolutePosition(
//                 config.button.mesh.getBoundingInfo().boundingBox.maximumWorld);
//             // this.anchor.isEnabled(false);
//
//             let buttonText = config.button?.text ? config.button.text : '';
//             this.button = Button.CreateSimpleButton(this.name + 'Button', buttonText);
//             this.button.width = config.button?.width ? config.button.width : "80px";
//             this.button.height = config.button?.height ? config.button.height : this.button.width;
//             this.button.hoverCursor = "pointer";
//             this.button.alpha = 1;
//             this.button.isEnabled = true;
//             this.button.isVisible = true;
//
//             this.button.thickness = 0;
//
//             if (config.button?.spriteManager) {
//                 this.sprite = new Sprite(this.name + 'Sprite', config.button.spriteManager);
//                 this.sprite.width = config.button?.spriteWidth ? config.button.spriteWidth : 0.1;
//                 this.sprite.height = config.button?.spriteHeight
//                     ? config.button.spriteHeight
//                     : this.sprite.width;
//                 this.sprite.position = this.anchor.getAbsolutePosition();
//                 this.sprite.isVisible = true;
//
//                 this.sprite.playAnimation(
//                     0,
//                     config.button.spriteManager.capacity,
//                     true,
//                     15
//                 );
//
//                 scene.registerAfterRender(_ => {
//                     this.sprite.position = this.anchor.getAbsolutePosition();
//                 })
//             }
//         }
//
//         aux.advTex.addControl(this.textBox);
//         this.textBox.addControl(this.textBlock);
//
//         if (config?.button) {
//             aux.advTex.addControl(this.button);
//             if (config.button?.mesh) this.button.linkWithMesh(this.anchor);
//             this.textBox.linkWithMesh(this.anchor); // SLOWS DOWN
//         }
//
//         this._animated = config?.animated && config.animated;
//         if (this._animated) {
//             this._hideTextAnimation = new AnimationSet(scene, config.name + 'TextHide', [{
//                 name: config.name + 'TextHideAlpha',
//                 target: this.textBox,
//                 targetProperty: 'alpha',
//                 dataType: BABYLON.Animation.ANIMATIONTYPE_FLOAT,
//                 easingFunction: powerEase,
//                 easingMode: BABYLON.EasingFunction.EASINGMODE_EASEOUT,
//                 keys: _ => [
//                     {frame: 0, value: this.textBox.alpha},
//                     {frame: 60, value: 0}
//                 ]
//             }], null, 1.618);
//
//             this._showTextAnimation = new AnimationSet(scene, config.name + 'TextShow', [{
//                 name: config.name + 'TextShowAlpha',
//                 target: this.textBox,
//                 targetProperty: 'alpha',
//                 dataType: BABYLON.Animation.ANIMATIONTYPE_FLOAT,
//                 easingFunction: powerEase,
//                 easingMode: BABYLON.EasingFunction.EASINGMODE_EASEOUT,
//                 keys: _ => [
//                     {frame: 0, value: this.textBox.alpha},
//                     {frame: 60, value: 1}
//                 ]
//             }], null, 1.618);
//
//             if (config?.button) {
//                 this._hideButtonAnimation = new AnimationSet(scene, config.name + 'TextHide', [{
//                     name: config.name + 'ButtonHideAlpha',
//                     target: this.sprite,
//                     targetProperty: 'color.a',
//                     dataType: BABYLON.Animation.ANIMATIONTYPE_FLOAT,
//                     easingFunction: powerEase,
//                     easingMode: BABYLON.EasingFunction.EASINGMODE_EASEOUT,
//                     keys: _ => [
//                         {frame: 0, value: this.button.alpha},
//                         {frame: 60, value: 0}
//                     ]
//                 }], null, 1.618);
//
//                 this._showButtonAnimation = new AnimationSet(scene, config.name + 'TextShow', [{
//                     name: config.name + 'ButtonShowAlpha',
//                     target: this.sprite,
//                     targetProperty: 'color.a',
//                     dataType: BABYLON.Animation.ANIMATIONTYPE_FLOAT,
//                     easingFunction: powerEase,
//                     easingMode: BABYLON.EasingFunction.EASINGMODE_EASEOUT,
//                     keys: _ => [
//                         {frame: 0, value: this.button.alpha},
//                         {frame: 60, value: 1}
//                     ]
//                 }], null, 1.618);
//             }
//         }
//     }
//
//     hideText() {
//         if (this._animated) {
//             this._hideTextAnimation.play(false, _ =>
//                 this.textBox.isEnabled = false
//             );
//         }
//     }
//
//     showText() {
//         if (this._animated) {
//             this.textBox.isEnabled = true;
//             this._showTextAnimation.play(false);
//         }
//     }
//
//     hideButton() {
//         if (this._animated && this._hideButtonAnimation) {
//             this._hideButtonAnimation.play(false, _ => {
//                 this.button.isEnabled = false;
//                 this.sprite.isVisible = false;
//             });
//         }
//     }
//
//     showButton() {
//         this.button.isEnabled = true;
//         this.sprite.isVisible = true;
//         if (this._animated && this._showButtonAnimation) {
//             this._showButtonAnimation.play(false);
//         }
//     }
// }