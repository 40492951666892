// @ts-nocheck

import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Scene} from "@babylonjs/core/scene";
import {ArcRotateCamera} from "@babylonjs/core/Cameras/arcRotateCamera";
// import {Scene, ArcRotateCamera, Vector3} from "babylonjs";
import {settings} from "../user/settings";
// import {AuxConfig} from "./auxiliaries";

interface CameraConfig {
    name: string,
    alpha?: number,
    beta?: number,
    radius?: number
}

export function createCamera(scene: Scene, config?: CameraConfig) {
    let name = config ? config.name : 'camera';
    let mergedOptions = Object.assign({}, settings.camera, config);


    let alpha = (config && config?.alpha) ? config.alpha : mergedOptions.alpha;
    let beta = (config && config?.beta) ? config.beta : mergedOptions.beta;
    let radius = (config && config?.radius) ? config.radius : mergedOptions.radius;

    let camera: ArcRotateCamera = new ArcRotateCamera(name, alpha, beta, radius, Vector3.Zero(), scene);

    if (mergedOptions.attachControl)
        camera.attachControl(settings.document.canvas, false);
    // camera.setMouseCamera = (active = mergedOptions.mouseCamera, options = {}) => {
    //     let control = options?.control ? options.control : true;
    //     let alphaOffset = options?.alphaOffset ? options.alphaOffset : 4.5;
    //     let betaOffset = options?.betaOffset ? options.betaOffset : 1.25;
    //     let alphaSens = options?.alphaSens ? options.alphaSens : viewer.canvas.width;
    //     let betaSens = options?.betaSens ? options.betaSens : viewer.canvas.height * 1.5;
    //
    //     if (active) {
    //         viewer.handleCamera = true;
    //         viewer.camera.detachControl(viewer.canvas, true);
    //         viewer.mouseCamera = {alpha: {}, beta: {}};
    //         viewer.mouseCamera.alpha.offset = alphaOffset;
    //         viewer.mouseCamera.beta.offset = betaOffset;
    //         viewer.mouseCamera.alpha.center = viewer.canvas.width / 2;
    //         viewer.mouseCamera.beta.center = viewer.canvas.height / 2;
    //         viewer.mouseCamera.alpha.sensitivity = alphaSens;
    //         viewer.mouseCamera.beta.sensitivity = betaSens;
    //         // viewer.camera.inputs.remove(viewer.camera.inputs.attached.mouse);
    //
    //         viewer.camera.inputs.attached.mousewheel.attachControl(viewer.canvas, false);
    //         let x, y, prevX, prevY, tcX, tcY;
    //         let tolX = viewer.canvas.width / 100;
    //         let tolY = viewer.canvas.height / 100;
    //         let ts = 1 / 60;
    //         let tc = 0.5;
    //         let den = ts + tc;
    //         let sensX, sensY;
    //         let kX = 0.005;
    //         let kY = 0.005;
    //         let lpfX = _ => {
    //             prevX = x === undefined ? viewer.scene.pointerX : x;
    //             tcX = Math.abs(x - prevX) < tolX ? 0.07 : tc;
    //             x = (viewer.scene.pointerX * ts + prevX * tcX) / (ts + tcX);
    //             // if (Math.abs(prevX - x) < viewer.canvas.width / 2000) return viewer.scene.pointerX;
    //             // sensX = (1 / (1 + Math.exp(-kX * (viewer.scene.pointerX - viewer.canvas.width / 2))))
    //             // sensX = Math.abs(2 * x / viewer.canvas.width - 1);
    //             // x *= 1 / sensX;
    //             // viewer.mouseCamera.alpha.sensitivity = alphaSens;
    //             return x;
    //         }
    //         let lpfY = _ => {
    //             prevY = y === undefined ? viewer.scene.pointerY : y;
    //             tcY = Math.abs(y - prevY) < tolY ? 0.07 : tc;
    //             y = (viewer.scene.pointerY * ts + prevY * tcY) / (ts + tcY);
    //             // if (Math.abs(prevY - y) < viewer.canvas.height / 2000) return viewer.scene.pointerY;
    //             // sensY = (1 / (1 + Math.exp(-kY * (viewer.scene.pointerY - viewer.canvas.height / 2))));
    //             // sensY = Math.abs(2 * y / viewer.canvas.height - 1);
    //             // y *= 1 / sensY;
    //             return y;
    //         }
    //         let cameraHandler = _ => {
    //             if (viewer.handleCamera) {
    //                 viewer.camera.alpha = viewer.mouseCamera.alpha.offset
    //                     - (lpfX() - viewer.mouseCamera.alpha.center) / viewer.mouseCamera.alpha.sensitivity;
    //                 viewer.camera.beta = viewer.mouseCamera.beta.offset
    //                     - (lpfY() - viewer.mouseCamera.beta.center) / viewer.mouseCamera.beta.sensitivity;
    //                 // viewer.camera.alpha = viewer.mouseCamera.alpha.offset
    //                 //     - (viewer.scene.pointerX - viewer.mouseCamera.alpha.center) / viewer.mouseCamera.alpha.sensitivity;
    //                 // viewer.camera.beta = viewer.mouseCamera.beta.offset
    //                 //     - (viewer.scene.pointerY - viewer.mouseCamera.beta.center) / viewer.mouseCamera.beta.sensitivity;
    //             }
    //         };
    //         viewer.camera.angularSensibility = 0;
    //         viewer.scene.preventDefaultOnPointerDown = false;
    //         viewer.canvas.addEventListener('mousemove', cameraHandler);
    //         // setInterval(_ => console.log(
    //         //     viewer.canvas.width / (1 + Math.exp(kX * (viewer.scene.pointerX - viewer.canvas.width / 2)))
    //         // ), 100)
    //     } else {
    //         viewer.handleCamera = false;
    //         if (control) viewer.camera.attachControl(viewer.canvas, false);
    //     }
    // }
    // viewer.camera.setMouseCamera(mouseCamera);

    if (mergedOptions?.upperRadiusLimit) camera.upperRadiusLimit = mergedOptions.upperRadiusLimit;
    if (mergedOptions?.lowerRadiusLimit) camera.lowerRadiusLimit = mergedOptions.lowerRadiusLimit;
    // if (mergedOptions?.alpha) camera.alpha = mergedOptions.alpha;
    // if (mergedOptions?.beta) camera.beta = mergedOptions.beta;
    // if (mergedOptions?.radius) camera.radius = mergedOptions.radius;
    camera.fov = mergedOptions.fov;
    camera.minZ = mergedOptions.minZ;
    camera.maxZ = mergedOptions.maxZ;
    // camera.target = new Vector3(-100, -100, -100);
    camera.wheelDeltaPercentage = 0.01;
    camera.pinchPrecision = 500;
    // if (viewer.isMobile) camera.angularSensibilityX = 0.1;

    if (mergedOptions.autoRotate) {
        camera.useAutoRotationBehavior = true;
        camera.autoRotationBehavior.idleRotationSpeed = mergedOptions.autoRotateSpeed || 0.05;
    }

    camera.checkCollisions = mergedOptions.checkCollisions;

    if (!mergedOptions.panEnabled) {
        // camera.inputs.attached.pointers.multiTouchPanAndZoom = false;
        // camera.inputs.attached.pointers.multiTouchPanning = false;
        camera.panningSensibility = 0;
    }

    if (!mergedOptions.moveEnabled) {
        // console.log(camera.inputs.attached.keyboard)
        // camera.inputs.attached.keyboard.keysUp = [];
        // camera.inputs.attached.keyboard.keysDown = [];
        // camera.inputs.attached.keyboard.keysLeft = [];
        // camera.inputs.attached.keyboard.keysRight = [];
    }

    // viewer.camera.adapt = function (model) {
    //     const boundingInfo = model.getBoundingInfo();
    //     // camera.radius = boundingInfo.boundingSphere.radius * 3;
    //     // camera.position = new Vector3(
    //     //     boundingInfo.boundingBox.centerWorld.x + boundingInfo.boundingSphere.radius * 2,
    //     //     boundingInfo.boundingBox.centerWorld.y + boundingInfo.boundingSphere.radius * 0.3,
    //     //     boundingInfo.boundingBox.centerWorld.z);
    //     viewer.camera.target = model.getBoundingInfo().boundingBox.centerWorld;
    // }

    // aux.assetContainer.cameras.push(camera);

    return camera;
}