import React from 'react';
import Logo from '../components/Logo';

export default function About() {

  return (
    <div
      id="about"
      className="section-content"
    >
      <h3 className="mail">
        📧 caner.erdogan@hotmail.com
      </h3>
      <p>I make immersive <span>3D web and AR experiences</span> with <span>full-responsive UI layouts</span>,
        using engineering knowledge and expertise to <span>build custom tools</span> for those. I am also a <a href="https://forum.babylonjs.com/u/caner" target="_blank">Jedi of <Logo filename="babylon.png" />Babylon JS forum!</a></p>
      <p>I consider myself a learner, an engineer, and a media enthusiast. My goal is, therefore, to use
        engineering knowledge and discipline to have a
        slightest <span>contribution in future technologies</span>. As a
        musician and an international professional, I am confident in my <span>communication, collaboration, documentation and presentation</span> skills.
      </p>
      <p>Until mid-2020, I was a senior automotive software engineer with 5 years of experience. The last couple
        of years of it is on control software <span>architecture design and engineering process automation tools development</span>.
        I am currently using this knowledge in <span>media and internet technologies, especially on 3D web experiences</span> design
        and development.</p>
      <p>I started with <span>testing, fixing and improving the quality</span> of the software functions in
        development. In a
        couple of weeks, I started to develop functions from scratch myself. In about a year, I was also
        assigned to assist the global quality and guidelines team of the company I was working at. This proved
        me reliable and eventually lead me to become the development responsible of a significantly important
        part of our work: <span>Safety Software Development.</span></p>
      <p>After almost <span>4 years of working at a global tier-1</span> engineering service supplier, I have
        qualified to <span>architect the powertrain control software</span> for a national project. There, I
        also composed a graphical user interface myself,
        to <span>speed up and automate the quality and testing</span> operations; therefore, saved a
        lot of time for our team of engineers, and <span>tens of thousands of dollars of software and engineering expense</span> for
        our company.</p>
      <p>For one of the projects in the company I was previously working at, I was tasked to obtain an accurate
        model of a <span>complex physical system</span> which was highly nonlinear due to frictional dynamics.
        For this
        purpose I composed a neural <span>network model generator algorithm</span> that works in hyperparameter
        spaces and
        automatically searches for the most accurate architecture for the neural network model.</p>
      <p>I have lived in many cities among various cultures, therefore, I can <span>communicate, collaborate and build relationships easily</span>.
        I am also <span>keen on learning tools and techniques</span> used in various fields of
        technology.</p>
      <p>I have always been an <span>organized</span> person, which promotes me to give documentation and
        presentation tasks
        utmost importance. This is because I would like to <span>ensure any other developer can easily follow and continue</span> with
        what is provided.</p>
      <p>I am also a <span>musician since 17 years</span> ago and performed <span>over 100 professional stages</span>. I have been
        improving myself in various <span>multimedia tools</span> and their <span>programming interfaces</span>, while creating content
        for our art. Using my engineering education and experience, I can easily adapt to various development
        environments and <span>think outside the box.</span></p>
    </div>
  );
}