import { motion } from 'framer-motion';
import React, { useState, useRef } from 'react';

export default function Cursor({ toggleDark, src, initial, animate, exit }) {
  return (
    <motion.img
      className={`cursor${toggleDark ? ' dark' : ''}`}
      src={src}
      initial={initial}
      animate={animate}
      exit={exit}
    />
  )
}