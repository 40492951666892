// @ts-nocheck

import {Scene} from "@babylonjs/core/scene";
import {AssetsManager, MeshAssetTask} from "@babylonjs/core/Misc/assetsManager";
import {TransformNode} from "@babylonjs/core/Meshes/transformNode";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
// import {Scene, AssetsManager, MeshAssetTask, AssetContainer, TransformNode} from "babylonjs";
// import {AdvancedDynamicTexture} from "babylonjs-gui";
import {settings, assets} from "../user/settings";
// import {Font3D} from "../component/annotation";

export interface AuxConfig {
    assetsManager: AssetsManager,
    blockerTasks: MeshAssetTask[],
    // assetContainer: AssetContainer,
    // advTex: AdvancedDynamicTexture | null,
    canvas: HTMLElement,
    mainRoot: TransformNode
}

export async function setupAux(scene: Scene, callback: Function) {
    let aux = {
        assetsManager: new AssetsManager(scene),
        blockerTasks: [],
        // assetContainer: new AssetContainer(scene),
        advTex: null,
        canvas: settings.document.canvas,
        mainRoot: new TransformNode('__root__', scene)
    };

    aux.mainRoot.id = '__root__';
    aux.mainRoot.rotation = Vector3.Zero();
    let done = false;

    let assetList = Object.keys(assets.models);
    if (assets?.textures) assetList.concat([Object.keys(assets.textures)]);



    // if (assetList.length === 0) callback();
    //
    // aux.assetsManager.onProgress = (remainingCount, totalCount, lastFinishedTask) => {
    //     console.log((totalCount - remainingCount) + ' out of ' + totalCount, lastFinishedTask.name);
    //     if (!done && aux.blockerTasks.every(task => task.isCompleted)) {
    //         done = true;
    //         callback();
    //     }
    // };
    //
    // aux.assetsManager.onTaskErrorObservable.add(eventData => {
    //     console.log(eventData.errorObject.message, eventData.errorObject.exception);
    // });

    // aux.assetsManager.onFinish = _ => callback();

    return aux;
}
