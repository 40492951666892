// @ts-nocheck

import {Engine} from "@babylonjs/core/Engines/engine";
import {Scene} from "@babylonjs/core/scene";
import {Color4} from "@babylonjs/core/Maths/math.color";
// import {Engine, Scene, Color4, CubeTexture} from "babylonjs";
import {settings, assets} from '../user/settings';

export function createScene(engine: Engine, options?: Object) {
    let scene = new Scene(engine, {
        useClonedMeshMap: settings.scene.useClonedMeshMap,
        useGeometryUniqueIdsMap: settings.scene.useGeometryUniqueIdsMap,
        useMaterialMeshMap: settings.scene.useMaterialMeshMap
    });

    if (settings.scene?.transparent) {
        scene.clearColor = new Color4(0, 0, 0, 0);
        scene.collisionsEnabled = true;
        scene.autoClear = false;
        scene.autoClearDepthAndStencil = false;
    } else if (settings.scene?.clearColor)
        scene.clearColor = options?.clearColor || settings.scene.clearColor;

    scene.getAnimationRatio();
    scene.blockfreeActiveMeshesAndRenderingGroups =
        settings.scene.blockfreeActiveMeshesAndRenderingGroups;

    if (settings.project.showFps) {
        if (settings.document?.fpsElement) scene.registerAfterRender(_ =>
            settings.document.fpsElement.innerHTML = engine.getFps().toFixed() + ' fps');
        else {
            let fpsElement = document.createElement('p');
            fpsElement.id = 'fps';
            fpsElement.style.position = 'absolute';
            fpsElement.style.top = '1rem';
            fpsElement.style.left = '1rem';
            document.body.appendChild(fpsElement);
            scene.registerAfterRender(_ =>
                fpsElement.innerHTML = engine.getFps().toFixed() + ' fps');
        }
    }
    return scene;
}
