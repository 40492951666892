import {Color4} from "@babylonjs/core/Maths/math.color";
// import {Color4, Vector3} from "babylonjs";

export const settings = {
    project: {
        postProcessUsage: false,
        mobileUsage: false,
        attachControl: true,
        data: {
            // Possible inputs from the main HTML element
            // modelName: 'data-model-name',
            // title: 'data-title',
            // description: 'data-description',
            // price: 'data-price'
        }
    },
    document: {
        canvas: document.getElementById('viewer')
    },
    engine: {
        canvas: document.getElementById('viewer') as HTMLCanvasElement,
        antialias: true,
        options: {},
        adaptDeviceRatio: true
    },
    scene: {
        useClonedMeshMap: true,
        useGeometryUniqueIdsMap: true,
        useMaterialMeshMap: true,
        transparent: false,
        clearColor: new Color4(0, 0, 0, 1),
        // clearColor: new Color4(0.102, 0.216, 0.302, 1),
        blockfreeActiveMeshesAndRenderingGroups: false,
        highlightEnabled: false
    },
    camera: {
        checkCollisions: false,
        alpha: Math.PI * 0.5,
        beta: Math.PI * 0.5,
        radius: 10,
        fov: 0.8,
        minZ: 0.001,
        maxZ: 50,
        autoRotate: false,
        autoRotateSpeed: 0.05,
        attachControl: true,
        panEnabled: true,
        moveEnabled: false,
        mouseCamera: false,
        upperRadiusLimit: 20,
        lowerRadiusLimit: 1
    }
}

interface IAssets {
    models?: Object,
    environment?: Object,
    textures?: Object,
    sprites?: Object,
    fonts?: Object
}

export const assets: IAssets = {
    // Images, sprites, sounds, textures, HDR/ENV, 360 photo
    models: {
        // character: {
        //     pathToModel: './assets/models/',
        //     modelFile: 'character.glb',
        //     scale: 0.5
        // }
        // head: {
        //     pathToModel: './assets/models/',
        //     modelFile: 'head.glb',
        //     checkCollisions: false,
        //     showBoundingBox: false,
        //     visible: true,
        //     scale: 2,
        //     // categories: {
        //     //     // 'building': 'BDG',
        //     //     // 'tree': 'TREE'
        //     // }
        // },
        // laptop: {
        //     pathToModel: './assets/models/',
        //     modelFile: 'laptop.glb',
        //     checkCollisions: false,
        //     showBoundingBox: true,
        //     visible: true,
        //     // pivotAndCenter: false,
        //     // category: laptop,
        //     hasBoundingBoxes: false,
        //     parts: {
        //         "ram": "Ram",
        //         "disk": "Disk",
        //         "motherboard": "Motherboard",
        //         "bottom": "Bottom",
        //         "body": "Body",
        //         "screen": "Screen"
        //     },
        // }
    },
    environment: {
        // bathroom: {
        //     filepath: 'assets/environment/bathroom.env',
        //     intensity: 1,
        //     rotation: 0,
        //     default: true
        // },
        default: {
            filepath: 'assets/environment/default.env',
            intensity: 3
        }
    },
    textures: {
        // sprite: 'assets/images/sprite.png'
    },
    fonts: {
        // chakra: 'assets/fonts/ChakraPetch-Regular.ttf',
        // ubuntu: 'assets/fonts/UbuntuCondensed-Regular.ttf',
    }
}
