import React, { useState, useEffect } from 'react';
import { Color4 } from "@babylonjs/core/Maths/math.color";
import './Home.css';
import { motion, AnimatePresence } from "framer-motion";
import { Viewer } from "../../viewer";
import { useViewer } from "../hooks/ViewerContext";
import { useTv } from "../hooks/TvContext";
import Experience from "./sections/Experience";
import Education from "./sections/Education";
import Projects from "./sections/Projects";
import About from "./sections/About";
import Interests from "./sections/Interests";
import Player from "./components/Player";
import Cursor from "./components/Cursor";
import { Rnd } from "react-rnd";

const players = [
  {
    remark: 'I play the cajón in this one:',
    video: 'https://www.youtube.com/embed/f0qQ_KM2KTc?&autoplay=1',
    image: 'assets/images/bmsa_thumb.jpg',
  },
  {
    remark: 'This one is my favorite:',
    video: 'https://www.youtube.com/embed/bld7y8tpiqw?&autoplay=1',
    image: 'assets/images/zd_thumb.jpg',
  },
  {
    remark: 'On stage:',
    video: 'https://www.youtube.com/embed/ovj1EHyzvzE?&autoplay=1',
    image: 'assets/images/bmsl_thumb.jpg',
  }
];

let initialized = false;
let tempViewer = null;

function Home() {
  const [debugMode, enableDebugMode] = useState(false);

  const [viewer, setViewer] = useViewer();
  const [tvEnabled, setTvEnabled, , , played, , hovered, setHovered, videoId,] = useTv();
  const [tvEverEnabled, setTvEverEnabled] = useState(false);
  const [dragged, setDragged] = useState(false);

  const [isPortrait, setIsPortrait] = useState(false);
  const [showHeading, setShowHeading] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [lightsClicked, setLightsClicked] = useState(false);
  const [dragGuide, setDragGuide] = useState(false);
  const [tvGuide, setTvGuide] = useState(false);
  const [section, setSection] = useState(null);
  const [sectionStyle, setSectionStyle] = useState({ x: 0, y: 0, height: '10rem' });

  const [sceneColor, setSceneColor] = useState('');
  const [vignetteColor, setVignetteColor] = useState('');
  const [toggleDark, setToggleDark] = useState(false);
  const [error, setError] = useState(false);

  const sections = {
    experience: {
      title: 'EXPERIENCE',
      component: () => (<Experience />),
      model: 'coder',
      animation: 'Typing'
    },
    about: {
      title: 'ABOUT ME',
      component: () => (<About />),
      model: 'drummer',
      animation: 'PlayingDrums'
    },
    projects: {
      title: 'SKILLS &\nPORTFOLIO',
      component: () => (<Projects />),
      model: 'engineer',
      animation: 'Using'
    },
    education: {
      title: 'EDUCATION',
      component: () => (<Education />),
      model: 'student',
      animation: 'Writing'
    },
    interests: {
      title: 'INTERESTS',
      component: () => (<Interests />),
      model: 'dancer',
      animation: 'SwingDancing'
    },
  }

  useEffect(() => {
    setViewer(new Viewer({
      verbose: false,
      models: {
        coder: {
          pathToModel: 'assets/models/',
          modelFile: 'cac-1641576542962.glb',
          scale: 0.05,
          visible: true
        },
        drummer: {
          pathToModel: 'assets/models/',
          modelFile: 'cac-1641576542962.glb',
          scale: 0.05,
          visible: true
        },
        dancer: {
          pathToModel: 'assets/models/',
          modelFile: 'cac-1641576542962.glb',
          scale: 0.05,
          visible: true
        },
        engineer: {
          pathToModel: 'assets/models/',
          modelFile: 'cac-1641576542962.glb',
          scale: 0.05,
          visible: true
        },
        student: {
          pathToModel: 'assets/models/',
          modelFile: 'cac-1641576542962.glb',
          scale: 0.05,
          visible: true
        },
        tablet: {
          pathToModel: 'assets/models/',
          modelFile: 'tablet.glb',
          visible: true
        },
        stick: {
          pathToModel: 'assets/models/',
          modelFile: 'stick.glb',
          scale: 0.1,
          visible: true
        },
        chair: {
          pathToModel: 'assets/models/',
          modelFile: 'chair.glb',
          scale: 0.01,
          visible: true
        },
      },
      scene: {
        transparent: true,
        clearColor: new Color4(0, 0, 0, 0),
      },
      camera: {
        autoRotate: true,
        radius: 75,
        upperRadiusLimit: 75,
        minZ: 0.1,
        maxZ: 110,
        alpha: 0,
        beta: 0.01
      },
      environment: {
        default: {
          filepath: 'assets/environment/default.env',
          intensity: 15
        }
      },
      fonts: {
        raleway: 'assets/fonts/Raleway-SemiBold.ttf'
      },
      metadata: {
        sections,
        ui: {
          showSection: key => setSection(key),
          updateSectionStyle: style => setSectionStyle(style),
          updateUIClass: isPortrait => setIsPortrait(isPortrait),
          nextFunction: null,
          previousFunction: null,
          updateEnableTv: isMobile => {
            !isMobile && !dragGuide && setDragGuide(true);
            setTvEnabled(!isMobile);
          },
          showHeading: show => setShowHeading(show),
          darkMode: false,
          textAnimation: null,
        },
        offsets: {
          coder: { model: 1.1, text: { x: 80, y: -15 } },
          drummer: { model: 1.1, text: { x: 25, y: 0 } },
          dancer: { model: 1, text: { x: 0, y: -8 } },
          engineer: { model: 1, text: { x: 25, y: 25 } },
          student: { model: 1.1, text: { x: 50, y: 15 } }
        }
      },
      rollback: () => {
        setError(true);
        setToggleDark(true);
      }
    }));
  }, []);

  useEffect(() => {
    if (viewer) {
      tempViewer = viewer;
      setViewer(tempViewer);
      window.viewer = viewer;
    }
  }, [viewer]);

  useEffect(() => {
    if (tvEnabled) {
      setTimeout(() => {
        setDragged(true);
      }, 6000);
    }
  }, [tvEnabled]);

  useEffect(() => {
    if (toggleDark) {
      setLightsClicked(true);
      document.documentElement.style.setProperty('--color', 'white');
      document.documentElement.style.setProperty('--inactive-color', '#626262');
      document.documentElement.style.setProperty('--active-color', '#34cee7');
      document.documentElement.style.setProperty('--emphasize-color', '#34cee7');
      document.documentElement.style.setProperty('--link-color', '#ca6dff');
      document.documentElement.style.setProperty('--dark-color', '#31374c');
      document.documentElement.style.setProperty('--tv-color', '#fab900');
      document.documentElement.style.setProperty('--background-color', '#232750');
    } else {
      document.documentElement.style.setProperty('--color', '#2d2d2d');
      document.documentElement.style.setProperty('--inactive-color', '#828282');
      document.documentElement.style.setProperty('--active-color', '#94f0ff');
      document.documentElement.style.setProperty('--emphasize-color', '#115ad0');
      document.documentElement.style.setProperty('--link-color', '#115ad0');
      document.documentElement.style.setProperty('--dark-color', '#31374c');
      document.documentElement.style.setProperty('--tv-color', '#2d2d2d');
      document.documentElement.style.setProperty('--background-color', '#e1e1e1');
    }
    if (!error && viewer) {
      viewer.metadata.darkMode = toggleDark;
      viewer.metadata.ui.colorAnimation();
    }
  }, [toggleDark]);

  useEffect(() => {
    if (dragGuide) {
      const callback = () => {
        setDragGuide(false);
        window.removeEventListener('click', callback);
      };
      window.addEventListener('click', callback);
    }
  }, [dragGuide]);

  useEffect(() => {showHeading && setShowColor(true)}, [showHeading]);

  return (
    <div className={"Home" + (isPortrait ? ' portrait' : '') + (toggleDark ? ' dark' : '')}>
      <div id="Home-wrapper">{error
        ? <h2 id="error">An error occured. Please visit later.</h2>
        : <>
          <AnimatePresence>{dragGuide && <Cursor
            src={"assets/images/drag.png"}
            initial={{ top: '20%', left: '10%', opacity: 0 }}
            animate={{
              top: ['25%', '20%'],
              left: ['12%', '20%'],
              opacity: 1,
              transition: {
                top: {
                  duration: 3,
                  repeat: Infinity,
                  repeatType: 'mirror',
                },
                left: {
                  duration: 3,
                  repeat: Infinity,
                  repeatType: 'mirror',
                },
              },
            }}
            exit={{ opacity: 0 }}
          />}</AnimatePresence>
          <AnimatePresence>{showHeading && <>
            <motion.h1
              id="name"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.618 }}
              exit={{ opacity: 0 }}
            >
              CANER{isPortrait ? <br /> : null}
              <span>ERDOĞAN</span>
            </motion.h1>
          </>}</AnimatePresence>
          <AnimatePresence>{section &&
            <>
              <motion.div className="section"
                style={{
                  top: sectionStyle.y,
                  left: sectionStyle.x,
                  width: sectionStyle.width,
                  height: sectionStyle.height,
                  transform: sectionStyle.transform,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="header">
                  <button onClick={() => viewer.metadata.ui.previousFunction?.()}>
                    {`< ${sections[Object.keys(sections)
                    [(Object.keys(sections).findIndex(key => key === section) - 1
                      + Object.keys(sections).length) % Object.keys(sections).length]].title}`}
                  </button>
                  <button onClick={() => viewer.metadata.ui.nextFunction?.()}>
                    {`${sections[Object.keys(sections)
                    [(Object.keys(sections).findIndex(key => key === section) + 1)
                      % Object.keys(sections).length]].title} >`}
                  </button>
                </div>
                {sections[section]?.text && <p>{sections[section].text}</p>}
                {sections[section]?.component?.()}
              </motion.div>
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                whileTap={{ scale: 0.9 }}
                id="back"
                onClick={() => viewer.metadata.states.main.begin()}
              >{'< Back to Home'}</motion.button>
            </>
          }</AnimatePresence>
          <AnimatePresence>{showColor && <>
            <motion.label
              className={`toggle${section ? ' top' : ''}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AnimatePresence
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {!lightsClicked && <p>LIGHTS</p>}
              </AnimatePresence>
              <div className="toggle__wrapper">
                {/*<input type="checkbox"/>*/}
                <input type="checkbox" onChange={() => setToggleDark(!toggleDark)} />
                <div className="toggle__bg">
                  <div className="toggle__sphere">
                    <div className="toggle__sphere-bg">
                    </div>
                    <div className="toggle__sphere-overlay"></div>
                  </div>
                </div>
              </div>
            </motion.label>
          </>}</AnimatePresence>
        </>}
      </div>
      {tvEnabled && !isPortrait && <Rnd
        id="tv"
        default={{
          x: 50,
          y: window.innerHeight - 266,
          width: 400,
          height: 216
        }}
        minWidth={300}
        maxWidth='45%'
        style={{ backgroundImage: 'url("assets/images/noise.gif")' }}
        lockAspectRatio={true}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onDragStart={() => setDragged(true)}
        onResizeStart={() => setDragged(true)}
      >
        <AnimatePresence>
          {tvEnabled && !dragged && <Cursor
            toggleDark={toggleDark}
            src={"assets/images/resize.png"}
            initial={{ top: -48, right: -48, opacity: 0 }}
            animate={{ top: -24, right: -24, scale: 0.5, opacity: 1 }}
            exit={{ top: -32, right: -32, opacity: 0 }}
          />}
          {hovered && !played && <motion.p
            key='more'
            initial={{ top: '2rem' }}
            animate={{ top: '-2rem' }}
            exit={{ top: '2rem' }}
          >Find more at <span>INTERESTS</span>!</motion.p>}
        </AnimatePresence>
        <AnimatePresence>
          {hovered && <motion.p
            key='close'
            className='close'
            initial={{ rotate: '160deg' }}
            animate={{ rotate: '90deg' }}
            exit={{ rotate: '160deg' }}
          >Click to <span>close</span></motion.p>}
        </AnimatePresence>
        <AnimatePresence>
          {hovered && !dragged && <motion.p
            key='more'
            className='drag'
            initial={{ bottom: '2rem' }}
            animate={{ bottom: '-2rem' }}
            exit={{ bottom: '2rem' }}
          >Drag and resize!</motion.p>}
        </AnimatePresence>
        <Player
          video={players[videoId].video}
          image={players[videoId].image}
        />
        <div
          className="overlay"
          style={{ backgroundImage: 'url("assets/images/tv.png")' }}>
        </div>
        <motion.button
          whileTap={{ scale: 0.9 }}
          style={{ backgroundImage: 'url("assets/images/emergency.png")' }}
          onClick={() => setTvEnabled(false)}
        >
        </motion.button>
      </Rnd>}
      <canvas id="viewer" />
      {debugMode && <>
        <p id="scene">{sceneColor}</p>
        <p id="vignette">{vignetteColor}</p>
      </>}
    </div>
  );
}

export default Home;
