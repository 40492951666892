// @ts-nocheck

import {BoundingInfo} from "@babylonjs/core/Culling/boundingInfo";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Mesh} from "@babylonjs/core/Meshes/mesh";
import {AbstractMesh} from "@babylonjs/core/Meshes/abstractMesh";
// import {BoundingInfo, Vector3, Mesh, AbstractMesh} from "babylonjs";

export function refreshBounding(
    model,
    world: boolean,
    annotation: boolean,
    box: Mesh | AbstractMesh = null
    // hasBoundingBoxes: boolean = false
) {
    if (box) return box.getBoundingInfo();

    let boundingInfo: BoundingInfo = null;

    let min = null;
    let max = null;

    let minProp = world ? "minimumWorld" : "minimum";
    let maxProp = world ? "maximumWorld" : "maximum";

    // let bag = viewer.scene.getMeshByName("BOX_Canta");
    model.getChildMeshes(false).forEach(mesh => {
        // if (hasBoundingBoxes && mesh.name === 'BOX_' + model.name)
        //     boundingInfo = mesh.getBoundingInfo();

        // if (boundingInfo !== null) return;

        // if (hasBoundingBoxes && !mesh.name.startsWith('BOX_')) return;
        if (mesh.isAnnotation && !annotation) return;
        const boundingBox = mesh.getBoundingInfo(false).boundingBox;

        if (min === null) {
            min = new Vector3();
            min.copyFrom(boundingBox[minProp]);
        }

        if (max === null) {
            max = new Vector3();
            max.copyFrom(boundingBox[maxProp]);
        }
        min = Vector3.Minimize(min, boundingBox[minProp]);
        max = Vector3.Maximize(max, boundingBox[maxProp]);

        // min.x = boundingBox[minProp].x < min.x ? boundingBox[minProp].x : min.x;
        // min.y = boundingBox[minProp].y < min.y ? boundingBox[minProp].y : min.y;
        // min.z = boundingBox[minProp].z < min.z ? boundingBox[minProp].z : min.z;
        //
        // max.x = boundingBox[maxProp].x > max.x ? boundingBox[maxProp].x : max.x;
        // max.y = boundingBox[maxProp].y > max.y ? boundingBox[maxProp].y : max.y;
        // max.z = boundingBox[maxProp].z > max.z ? boundingBox[maxProp].z : max.z;
    });

    if (min && max) {
        boundingInfo = new BoundingInfo(min, max);
        model.setBoundingInfo(boundingInfo);
    }
    return boundingInfo;
}
