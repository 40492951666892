// @ts-nocheck

import { Engine } from "@babylonjs/core/Engines/engine";
import { DefaultLoadingScreen } from "@babylonjs/core/Loading/loadingScreen";
import { Scene } from "@babylonjs/core/scene";
import { Color3, Color4 } from "@babylonjs/core/Maths/math.color";
import { Space } from "@babylonjs/core/Maths/math.axis";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { ImageProcessingPostProcess } from "@babylonjs/core/PostProcesses/imageProcessingPostProcess";
import { DefaultRenderingPipeline } from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline";
import { createCamera } from "../system/camera";
import { AnimationSet } from "../graphics/animation";
import { Text3D } from "../component/annotation";
import { normalizeAngle } from "../utility/utilities";

export async function setupLoading(engine: Engine, fonts?: Object) {
  return new Loading(engine, fonts);
}

class Loading {
  engine: Engine;
  scene: Scene;
  camera: ArcRotateCamera;
  animation: AnimationSet;
  isDisposed: boolean;
  progressFunction: Function;

  postProcess: any;

  constructor(engine, fonts?: Object) {
    console.log("LOADING");
    this.isDisposed = false;
    this.engine = engine;

    DefaultLoadingScreen.prototype.displayLoadingUI = () => {};
    this.engine.loadingScreen.hideLoadingUI();

    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0, 0, 0, 0);

    this.camera = createCamera(this.scene, {
      name: "loadingCamera",
      alpha: Math.PI * 0.5,
      beta: 0,
      radius: 5,
    });
    this.camera.upperBetaLimit = null;
    this.camera.beta = 0;
    this.camera.detachControl();

    const defaultPipeline = new DefaultRenderingPipeline(
      "default",
      false,
      this.scene,
      [this.camera]
    );
    defaultPipeline.sharpenEnabled = true;
    //
    if (viewer.device.tier > 1 || !viewer.device.isMobile) {
      defaultPipeline.fxaaEnabled = true;
      defaultPipeline.samples = 4;
    } else {
      defaultPipeline.fxaaEnabled = false;
      defaultPipeline.samples = 1;
    }

    this.postProcess = new ImageProcessingPostProcess(
      "processing",
      1.0,
      this.camera
    );
    this.postProcess.fromLinearSpace = false;

    this.postProcess.vignetteWeight = 1;
    this.postProcess.vignetteCameraFov = 1;
    this.postProcess.vignetteColor = new Color4(0, 0, 0, 1);
    this.postProcess.vignetteEnabled = true;
    this.postProcess.vignetteBlendMode = 1;

    if (window.innerWidth / window.innerHeight < 1) {
      this.postProcess.vignetteWeight = 4;
      this.postProcess.vignetteStretch = 1;
    } else {
      this.postProcess.vignetteWeight = 1;
      this.postProcess.vignetteStretch = 0;
    }

    const loadingMat = new StandardMaterial("loadingMat", this.scene);
    loadingMat.emissiveColor = Color3.Black();
    loadingMat.backFaceCulling = false;

    const text = new Text3D(
      fonts.raleway,
      "PLEASE WAIT",
      {
        material: loadingMat,
        size: 0.25,
        depth: 0.015,
      },
      this.scene
    );

    text.mesh.setPivotPoint(
      text.mesh._boundingInfo.boundingBox.center,
      Space.LOCAL
    );
    text.mesh.locallyTranslate(
      text.mesh._boundingInfo.boundingBox.center.scale(-1)
    );
    text.mesh.rotation.y = Math.PI;

    let nAng;
    const PI05 = Math.PI * 0.5;
    this.scene.registerBeforeRender((_) => {
      text.mesh.rotation.x += 0.1;
      nAng = normalizeAngle(text.mesh.rotation.x);
      if (nAng > -PI05 && nAng < PI05) text.mesh.scaling.z = 1;
      else text.mesh.scaling.z = -1;
    });

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  }

  finish(callback) {
    callback();
  }

  dispose() {
    this.scene.meshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.postProcess.dispose();
    this.scene.dispose();
    this.isDisposed = true;
  }
}
