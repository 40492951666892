// @ts-nocheck

import {Color4} from "@babylonjs/core/Maths/math.color";
import {ImageProcessingPostProcess} from "@babylonjs/core/PostProcesses/imageProcessingPostProcess";
import {DefaultRenderingPipeline} from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline";
// import {
//     Color4,
//     ImageProcessingPostProcess,
//     DefaultRenderingPipeline} from "babylonjs";

export function postProcess(viewer) {
    const {scene, camera} = viewer;
    // return;

    const defaultPipeline = new DefaultRenderingPipeline("default", false, scene, [camera]);
    defaultPipeline.sharpenEnabled = true;
    // defaultPipeline.sharpen.edgeAmount = 0.01;
    // defaultPipeline.imageProcessingEnabled = true;
    // // defaultPipeline.imageProcessing.contrast = 1.2;
    // // defaultPipeline.imageProcessing.exposure = 1.2;
    //
    if (viewer.device.tier > 1 || !viewer.device.isMobile) {
        // defaultPipeline.depthOfFieldEnabled = true;
        // defaultPipeline.depthOfFieldBlurLevel = 2;
        // defaultPipeline.depthOfField.focusDistance = 4.5;
        // defaultPipeline.depthOfField.focalLength = 15;
        // defaultPipeline.depthOfField.fStop = 10;

        defaultPipeline.fxaaEnabled = true;
        defaultPipeline.samples = 4;

        let tout;
        window.addEventListener('resize', _ => tout =
            setTimeout(_ => viewer.engine.resize(), 250));
    } else {
        defaultPipeline.fxaaEnabled = false;
        defaultPipeline.samples = 1;
    }


    // return {}
    const postProcess = new ImageProcessingPostProcess("processing", 1.0, camera);
    postProcess.fromLinearSpace = false;
    postProcess.vignetteWeight = 1;//0.4;
    postProcess.vignetteCameraFov = 1;
    // postProcess.vignetteStretch = 0.5;
    // postProcess.vignetteCentreY = -0.5;
    // postProcess.vignetteColor = new Color4(0.601, 0, 0.305, 1);
    postProcess.vignetteColor = new Color4(0, 0, 0, 1);
    postProcess.vignetteEnabled = true;
    postProcess.vignetteBlendMode = 1;

    return postProcess;
}
