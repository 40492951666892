// @ts-nocheck

import {Animation} from "@babylonjs/core/Animations/animation";
import {AnimationGroup} from "@babylonjs/core/Animations/animationGroup";
import {EasingFunction, PowerEase, ExponentialEase} from "@babylonjs/core/Animations/easing";
import {Mesh} from "@babylonjs/core/Meshes/mesh";
import {Node} from "@babylonjs/core/node";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Scene} from "@babylonjs/core/scene";
import {Camera, ArcRotateCamera} from "@babylonjs/core/Cameras/arcRotateCamera";
// import {
//     Animation,
//     AnimationGroup,
//     EasingFunction,
//     PowerEase,
//     ExponentialEase,
//     Mesh,
//     Node,
//     Vector3,
//     Scene,
//     ArcRotateCamera,
//     Camera
// } from "babylonjs";
// import {AuxConfig} from "../system/auxiliaries";
import {normalizeAngle} from "../utility/utilities";
import {Model} from "../component/model";

interface KeyframeConfig { frame: any, value: any }
interface AnimationConfig {
    target: any,
    name: string,
    targetProperty: string,
    dataType: number,
    easing: boolean,
    easingFunction: EasingFunction,
    easingMode: number,
    framerate: number,
    keys: any, //KeyframeConfig[] | Function,
    loop: boolean | number,
    loopMode: number,
    speed: number
}

export class AnimationSet {
    name: string;
    // animations: any[];
    callback: Function = (() => {});
    prequel: Function = (() => {});
    speed: number = 1;

    animations: any[];
    animGroup: AnimationGroup;
    animationGroups: AnimationGroup[];
    calibrateableAnimations: {animation: Animation, keys: any}[];
    additionalAnimations: any[];
    easing: boolean;
    _bound: any;
    _additionalsPlayed: boolean;

    constructor(
        scene: Scene,
        name: string,
        animations: any[],//(AnimationConfig | AnimationGroup)[],
        callback: Function = (() => {}),
        speed: number = 1,
        prequel: Function = (() => {}),
        setup: boolean = true
    ) {
        this.name = name;
        this.animations = animations;
        this.callback = callback;
        this.prequel = prequel;
        this.speed = speed;
        this.animationGroups = [];
        this.calibrateableAnimations = [];
        this._additionalsPlayed = false;

        let animGroup = scene.getAnimationGroupByName(name);
        if (animGroup !== null) animGroup.dispose();
        this.animGroup = new AnimationGroup(name, scene);

        if (setup) this.setup(animations);
    }

    setup(animations) {
        this.animationGroups = [];
        this.calibrateableAnimations = [];
        animations.forEach(animation => {
            if (!animation) return;
            if (animation instanceof AnimationGroup) this.animationGroups.push(animation);
            else {
                if (animation.loop !== undefined) {
                    if (typeof animation.loop === 'boolean')
                        animation.loopMode = animation.loop
                            ? Animation.ANIMATIONLOOPMODE_CYCLE
                            : Animation.ANIMATIONLOOPMODE_CONSTANT;
                    else animation.loopMode = animation.loop;
                } else animation.loopMode = Animation.ANIMATIONLOOPMODE_CONSTANT;
                animation.framerate = animation.framerate === undefined ? 60 : animation.framerate;
                animation.loop = animation.loop === undefined
                    ? Animation.ANIMATIONLOOPMODE_CONSTANT : animation.loop;
                let a = new Animation(
                    animation.name,
                    animation.targetProperty,
                    animation.framerate,
                    animation.dataType,
                    animation.loopMode
                );
                if (typeof animation.keys === 'function') {
                    a.setKeys(animation.keys());
                    this.calibrateableAnimations.push({animation: a, keys: animation.keys});
                }
                else a.setKeys(animation.keys);

                this.easing = animation?.easing || animation?.easingFunction || animation?.easingMode;
                if (this.easing) {
                    // if (!(!anim?.easing && !anim.easing)) {
                    // if (name === 'focus') console.log(anim.easingFunction)
                    let easingFunction = animation.easingFunction !== undefined ? animation.easingFunction : new PowerEase();
                    let easingMode = animation.easingMode !== undefined
                        ? animation.easingMode
                        : EasingFunction.EASINGMODE_EASEOUT;
                    a.setEasingFunction(easingFunction);
                    easingFunction.setEasingMode(easingMode);
                }
                this.animGroup.addTargetedAnimation(a, animation.target);
            }
        });
        this.animGroup.speedRatio = this.speed;
        this.animationGroups.push(this.animGroup);
        // if (aux.animationSets === undefined) aux.animationSets = {};
        // if (callback !== undefined) animationSet[0].onAnimationGroupEndObservable.add(_ => {
        //     // viewer.model.refreshBounding();
        //     callback();
        // });
        // aux.animationSets[name] = animationSet;
    }

    play(
        loop: boolean = false,
        seq: Function = (_ => {}),
        additionalAnimations: any = [],
        reverse: boolean = false,
    ): void {
        this.prequel && this.prequel();
        this.calibrateableAnimations.forEach(cal => cal.animation.setKeys(cal.keys()));
        if (additionalAnimations && additionalAnimations.length > 0) {
            this.setup(this.animations.concat(additionalAnimations));
            this._additionalsPlayed = true;
        }
        else {
            this.setup(this.animations);
            this._additionalsPlayed = false;
        }
        if (reverse) this.animationGroups.forEach(animGroup =>
            animGroup.start(loop, this.speed, animGroup.to, animGroup.from));
        else this.animationGroups.forEach(animGroup => animGroup.play(loop));
        this.animationGroups[0].onAnimationGroupEndObservable.addOnce(_ => {
            this.callback && this.callback();
            if (seq) seq();
            // animationSet[0].clearObservables();
        });
    }

    stop() {
        this.animationGroups.forEach(animGroup => animGroup.stop());
        this.animationGroups[0].onAnimationGroupEndObservable.clear();
    }
}

interface FocusConfig {
    name: string,
    targetMesh: Mesh,
    location?: Function | Vector3,
    radiusFactor?: number,
    radiusLimits?: [number, number],
    radius?: number,
    alpha?: number,
    beta?: number,
    framerate?: number,
    fov?: number,
    additionalAnimations?: [],
    easingFunction?: EasingFunction,
    easingMode?: number,
    speed?: number
}

const exponentialEase = new ExponentialEase();

export class FocusAnimationSet extends AnimationSet {
    name: string;
    callback: Function;
    speed: number;
    framerate: number;
    radius: number;
    radiusFactor: number;
    radiusLimits: [number, number];
    alpha: number;
    beta: number;
    // additionalAnimations: [];
    fov: number;
    _camera: Camera | ArcRotateCamera;
    _targetMesh: Mesh | Model;
    easingFunction: any;
    easingMode: any;
    location: Function;
    prequel: Function;

    constructor(
        scene: Scene,
        camera: ArcRotateCamera,
        config: FocusConfig,
        callback?: Function,
        prequel?: Function
    ) {
        super(
            scene,
            config.name,
            [],
            callback,
            config.speed,
            () => {
                camera.update();
                camera.rebuildAnglesAndRadius();
                if (prequel) prequel();
            },
            false
        );

        this.callback = callback === undefined || callback === null
            ? () => {} //camera.target = config.targetMesh
            : () => {
                //camera.target = config.targetMesh;
                callback();
            };
        this.framerate = config?.framerate ? config.framerate : 60;
        this.radiusFactor = config?.radiusFactor ? config.radiusFactor : 3;
        this.radiusLimits = config?.radiusLimits ? config.radiusLimits : [0.8, 1.2];
        this.alpha = config.alpha !== undefined ? config.alpha : -Math.PI / 4;
        this.beta = config.beta !== undefined ? config.beta : Math.PI / 3;
        this.radius = config?.radius;
        // this.additionalAnimations = config?.additionalAnimations ? config.additionalAnimations : [];
        this.speed = config?.speed ? config.speed : 1;
        this.fov = config?.fov ? config.fov : 0.8;

        this.easingFunction = config?.easingFunction;
        this.easingMode = config?.easingMode;

        this._camera = camera;
        this._targetMesh = config.targetMesh;

        if (config?.location) {
            if (typeof config.location === 'function') this.location = config.location;
            else this.location = _ => config.location;
        }
        // else this.location = _ => config.targetMesh.getAbsolutePosition();
        else this.location = _ => config.targetMesh.getBoundingInfo().boundingBox.centerWorld;

        this.additionalAnimations = config?.additionalAnimations ? config.additionalAnimations : [];

        this.setup(this.animations.concat(this.additionalAnimations));
    }

    setup(animations) {
        this.animations = [
            {
                target: this._camera,
                name: this.name + "CamTarget",
                targetProperty: "target",
                dataType: Animation.ANIMATIONTYPE_VECTOR3,
                easingFunction: this.easingFunction,
                easingMode: this.easingMode,
                keys: _ => [
                    {frame: 0, value: this._camera.target},
                    {frame: this.framerate, value: _ => this.location()}
                ]
            },
            {
                target: this._camera,
                name: this.name + "CamRadius",
                targetProperty: "radius",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                // easing: true,
                easingFunction: this.easingFunction,
                easingMode: this.easingMode,
                keys: _ =>  [
                    {frame: 0, value: this._camera.radius},
                    {
                        frame: this.framerate,
                        value: this.radius ? this.radius :
                            Math.min(Math.max(this._camera.lowerRadiusLimit,
                                this._targetMesh.getBoundingInfo().diagonalLength *
                                this.radiusFactor),
                                camera.upperRadiusLimit)
                    }
                ]
            },
            {
                target: this._camera,
                name: this.name + "CamLoRadLim",
                targetProperty: "lowerRadiusLimit",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                easingFunction: this?.easingFunction,
                easingMode: this?.easingMode,
                keys: _ => [
                    {frame: 0, value: this._camera.lowerRadiusLimit},
                    {
                        frame: this.framerate,
                        value: this?.radius
                            ? this.radius * this.radiusLimits[0]
                            : this._targetMesh.getBoundingInfo().diagonalLength *
                            this.radiusFactor * this.radiusLimits[0]
                    }
                ]
            },
            {
                target: this._camera,
                name: this.name + "CamUpRadLim",
                targetProperty: "upperRadiusLimit",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                easingFunction: this?.easingFunction,
                easingMode: this?.easingMode,
                keys: _ => [
                    {frame: 0, value: this._camera.upperRadiusLimit},
                    {
                        frame: this.framerate,
                        value: this?.radius
                            ? this.radius * this.radiusLimits[1]
                            : this.targetMesh.getBoundingInfo().diagonalLength *
                            this.radiusFactor * this.radiusLimits[1]
                    }
                ]
            },
            {
                target: this._camera,
                name: this.name + "CamAlpha",
                targetProperty: "alpha",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                easingFunction: this?.easingFunction,
                easingMode: this?.easingMode,
                keys: _ => [
                    {frame: 0, value: normalizeAngle(this._camera.alpha)},
                    {
                        frame: this.framerate,
                        value:
                            Math.abs(normalizeAngle(this.alpha) + Math.PI * 2 - normalizeAngle(this._camera.alpha)) >
                            Math.abs(normalizeAngle(this.alpha) - normalizeAngle(this._camera.alpha))
                                ? normalizeAngle(this.alpha)
                                : normalizeAngle(this.alpha) + Math.PI * 2
                    }
                ]
            },
            {
                target: this._camera,
                name: this.name + "CamBeta",
                targetProperty: "beta",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                easingFunction: this?.easingFunction,
                easingMode: this?.easingMode,
                keys: _ => [
                    {frame: 0, value: this._camera.beta % (Math.PI * 2)},
                    {frame: this.framerate, value: this.beta}
                ]
            }
        ];

        if (this?.fov) this.animations.push({
            target: this._camera,
            name: this.name + "CamFov",
            targetProperty: "fov",
            dataType: Animation.ANIMATIONTYPE_FLOAT,
            easingFunction: exponentialEase,
            easingMode: EasingFunction.EASINGMODE_EASEINOUT,
            keys: _ => [
                {frame: 0, value: this._camera.fov},
                {frame: this.framerate, value: this.fov}
            ]
        });

        super.setup(animations);
    }
}

interface OpacityConfig {
    name: string,
    targetMesh: Mesh | Node,
    value: number,
    applyToChildren?: boolean,
    framerate?: number,
    additionalAnimations?: [],
    easingFunction?: EasingFunction,
    easingMode?: number,
    speed?: number
}

export class OpacityAnimationSet extends AnimationSet {
    framerate: number;
    // additionalAnimations: [];

    constructor(
        scene: Scene,
        config: OpacityConfig,
        callback?: Function
    ) {
        super(
            scene,
            config.name,
            [],
            callback,
            config.speed
        );
        this.framerate = config?.framerate ? config.framerate : 60;
        // this.additionalAnimations = config?.additionalAnimations ? config.additionalAnimations : [];
        this.speed = config?.speed ? config.speed : 2.618;

        let applyToChildren = config?.applyToChildren ? config.applyToChildren : true;
        let additionalAnimations = config?.additionalAnimations ? config.additionalAnimations : [];

        let animations = [];
        let meshes = config.targetMesh.getChildMeshes().concat(config.targetMesh);
        // let meshes = config.targetMesh.getChildren().concat(config.targetMesh);
        // meshes = meshes.filter(mesh => mesh.getClassName().includes('Mesh'));
        let getKeys = mesh => [
            {frame: 0, value: mesh.visibility},
            {frame: this.framerate, value: config.value}
        ];
        if (applyToChildren) {
            for (let child of meshes) {
                if (child?.name && !child.name.includes('BOX')) animations.push({
                    target: child,
                    name: child.name + config.name + "Opacity",
                    targetProperty: "visibility",
                    dataType: Animation.ANIMATIONTYPE_FLOAT,
                    easingFunction: config?.easingFunction,
                    easingMode: config?.easingMode,
                    keys: _ => getKeys(child)
                });
            }
        } else {
            animations.push({
                target: config.targetMesh,
                name: config.targetMesh.name + config.name + "Opacity",
                targetProperty: "visibility",
                dataType: Animation.ANIMATIONTYPE_FLOAT,
                easingFunction: config?.easingFunction,
                easingMode: config?.easingMode,
                keys: _ => getKeys(config.targetMesh)
            });
        }

        this.setup(animations.concat(additionalAnimations));
    }
}