// @ts-nocheck

import {Scene} from "@babylonjs/core/scene";
import {Texture} from "@babylonjs/core/Materials/Textures/texture";
import {TextureAssetTask} from "@babylonjs/core/Misc/assetsManager";
import {PBRMaterial} from "@babylonjs/core/Materials/PBR/pbrMaterial";
import {Color3} from "@babylonjs/core/Maths/math.color";
import {assets} from "../user/settings";
// import {Scene, Texture, TextureAssetTask} from "babylonjs";
import {AuxConfig} from "../system/auxiliaries";

export const textureLibrary = {
    Env_M: {
        mesh: 'HDR',
        material: 'Env_M',
        renderingGroupId: 0,
        // albedoColor: color('000000'),
        // roughness: 1,
        indexOfRefraction: 1,
        albedoTexture: 'HDR.jpg',
        // emissiveTexture: null,
        // emissiveColor: white,
    },
    Rock_M: {
        mesh: 'Rock',
        material: 'Rock_M',
        renderingGroupId: 2,
        // albedoColor: color('000000'),
        // roughness: 1,
        indexOfRefraction: 1,
        transparencyMode: PBRMaterial.PBRMATERIAL_ALPHABLEND,
        albedoTexture: 'Rock.png',
        albedoTextureHasAlpha: true,
        useAlphaFromAlbedoTexture: true,
        // emissiveTexture: null,
        // emissiveColor: white,
    },
    Tree_M: {
        mesh: 'Tree',
        material: 'Tree_M',
        renderingGroupId: 1,
        // albedoColor: color('000000'),
        // roughness: 1,
        indexOfRefraction: 1,
        transparencyMode: PBRMaterial.PBRMATERIAL_ALPHABLEND,
        albedoTexture: 'Tree.png',
        albedoTextureHasAlpha: true,
        useAlphaFromAlbedoTexture: true,
        // emissiveTexture: null,
        // emissiveColor: white,
    },

    uk5ocf2_M: {
        mesh: 'uk5ocf2',
        material: 'uk5ocf2_M',
        renderingGroupId: 3,
        // albedoColor: color('000000'),
        // roughness: 1,
        indexOfRefraction: 1,
        // transparencyMode: PBRMaterial.PBRMATERIAL_ALPHABLEND,
        albedoTexture: 'uk5ocf2_Diff.jpg',
        bumpTexture: 'uk5ocf2_Normal.jpg',
        // albedoTextureHasAlpha: true,
        // useAlphaFromAlbedoTexture: true,
        // emissiveTexture: null,
        // emissiveColor: white,
    },




};

export const colorGradingLibrary = {};

export function setupTextures(scene: Scene, aux?: AuxConfig) {
    let textures: Object = {};
    let task: TextureAssetTask

    if (assets?.textures) for (const [key, url] of Object.entries(assets.textures)) {
        textures[key] = new Texture(url, scene);
        textures[key].url = url;

        // task = aux.assetsManager.addTextureTask(key, url);
        // task.onSuccess = subtask => textures[key] = subtask.texture;
    }

    return textures;
}

export function loadTexture(key, url, scene: Scene, textures: Object) {
    let exists = false;
    Object.values(textures).forEach(texture => {
        if (!exists && texture.url === url)
            exists = true;
        textures[key] = texture;
    });
    if (exists) return;
    textures[key] = new Texture(url, scene);
    textures[key].url = url;
    console.log(textures)
}

// export function loadColorGradingTexture(key, url, scene: Scene, textures: Object) {
//     let exists = false;
//     Object.values(textures).forEach(texture => {
//         if (!exists && texture.url === url)
//             exists = true;
//         textures[key] = texture;
//     });
//     if (exists) return;
//     textures[key] = new BABYLON.ColorGradingTexture(url, scene);
//     textures[key].url = url;
// }

const white = Color3.White();
const black = Color3.Black();

let colorLibrary = {};
let match, c;
const color = (hex) => {
    if (colorLibrary[hex]) return colorLibrary[hex];
    else {
        match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        c = [
            parseInt(match[1], 16) / 255,
            parseInt(match[2], 16) / 255,
            parseInt(match[3], 16) / 255
        ];
        colorLibrary[hex] = new Color3(...c);
    }
    return colorLibrary[hex];
}

// export const textureLibrary = {
//     education: {
//         Book: {
//             mesh: 'Book',
//             material: 'Book_M',
//             albedoColor: color('000000'),
//             roughness: 1,
//             indexOfRefraction: 1,
//             lightmapTexture: 'Book_Texture.jpg',
//             // emissiveTexture: null,
//             // emissiveColor: white,
//         },
//         Branding: {
//             mesh: 'Branding',
//             material: 'Branding_M',
//             albedoColor: color('353535'),
//             roughness: 0.25,
//             // indexOfRefraction: 1,
//             // lightmapTexture: 'Book_Texture.jpg',
//             // emissiveTexture: null,
//             // emissiveColor: white,
//         },
//     },
//     gaming: {
//         Arcade: {
//             mesh: 'Arcade',
//             material: 'Arcade_M',
//             albedoColor: color('000000'),
//             // emissiveColor: color('999999'),
//             roughness: 1,
//             indexOfRefraction: 1,
//             // albedoTexture: 'Office_Branding_Texture.jpg',
//             lightmapTexture: 'Arcade_Texture.jpg',
//             // emissiveTexture: 'Office_Branding_Texture.jpg',
//             // opacityTexture: 'Hologram_Texture.png',
//         },
//         MonsterLAB_Cam: {
//             mesh: 'MonsterLAB_Cam',
//             material: 'MonsterLab_Cam_M',
//             albedoColor: color('0026a0'),
//             // emissiveColor: color('ffffff'),
//             alpha: 0.5,
//             roughness: 0.1,
//             indexOfRefraction: 1.5,
//             transparencyMode: BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND,
//             // albedoTexture: 'Microsoft_Brand_Texture.jpg',
//             // lightmapTexture: 'Microsoft_Brand_Texture.jpg',
//             // emissiveTexture: 'Microsoft_Brand_Texture.jpg',
//             // opacityTexture: 'Hologram_Texture.png',
//         },
//     },
//     general: {
//         Arka_Masa: {
//             mesh: 'Arka_Masa',
//             material: 'Arka_Masa_M',
//             albedoColor: color('000000'),
//             // emissiveColor: color('ffffff'),
//             roughness: 1,
//             indexOfRefraction: 1,
//             // albedoTexture: 'Office_Branding_Texture.jpg',
//             lightmapTexture: 'Arka_Masa_Texture.jpg',
//             // emissiveTexture: 'Office_Branding_Texture.jpg',
//             // emissiveColor: '00A8FF',
//             // opacityTexture: 'Hologram_Texture.png',
//         },
//         Arka_Tavan: {
//             mesh: 'Arka_Tavan',
//             material: 'Arka_Tavan_M',
//             albedoColor: color('000000'),
//             // emissiveColor: color('ffffff'),
//             roughness: 1,
//             indexOfRefraction: 1,
//             // albedoTexture: 'Office_Branding_Texture.jpg',
//             lightmapTexture: 'Arka_Tavan_Texture.jpg',
//             // emissiveTexture: 'Office_Branding_Texture.jpg',
//             // emissiveColor: '00A8FF',
//             // opacityTexture: 'Hologram_Texture.png',
//         },
//     }
// }

// const colorGradingLibrary = {
//     // education: `./assets/images/education.3DL`,
//     // gaming: null,//`./assets/images/General_01 (1).3DL`,
//     // general: null//`./assets/images/General_01 (1).3DL`,
//     // // general: `./assets/images/general_lut.png`,
// }

export async function initializeTextures(url, promises, viewer, config?) {
    let mesh;
    let material;
    let albedoColor;
    let roughness;
    let indexOfRefraction;
    let lightmapTexture;
    let emissiveTexture;
    let emissiveColor;
    let colorGrading;

    for (const [key, config] of Object.entries(textureLibrary)) {
        if (config?.albedoTexture) {
            promises.push(new Promise(resolve => {
                resolve(loadTexture(
                    key + 'albedo',
                    `${url}/${config.albedoTexture}`,
                    viewer.scene,
                    viewer.textures));
            }));
        }
        if (config?.bumpTexture) {
            promises.push(new Promise(resolve => {
                resolve(loadTexture(
                    key + 'bump',
                    `${url}/${config.bumpTexture}`,
                    viewer.scene,
                    viewer.textures));
            }));
        }
        if (config?.emissiveTexture) {
            promises.push(new Promise(resolve => {
                resolve(loadTexture(
                    key + 'emissive',
                    `${url}/${config.emissiveTexture}`,
                    viewer.scene,
                    viewer.textures));
            }));
        }
        if (config?.lightmapTexture) {
            promises.push(new Promise(resolve => {
                resolve(loadTexture(
                    key + 'lightmap',
                    `${url}/${config.lightmapTexture}`,
                    viewer.scene,
                    viewer.textures));
            }));
        }
        if (config?.opacityTexture) {
            promises.push(new Promise(resolve => {
                resolve(loadTexture(
                    key + 'opacity',
                    `${url}/${config.opacityTexture}`,
                    viewer.scene,
                    viewer.textures));
            }));
        }
    }

    // Object.entries(textureLibrary).forEach(entry => {
    //     // if (entry[0] === 'sphere') return;
    // });

    // if (colorGradingLibrary[name] !== null)
    //     promises.push(new Promise(resolve => {
    //         resolve(loadColorGradingTexture(
    //             'Lut',
    //             colorGradingLibrary[name],
    //             viewer.scene,
    //             viewer.textures));
    //     }));

    if (promises.length > 0)
        await Promise.all(promises);

    for (const [key, config] of Object.entries(textureLibrary)) {
        if (config?.mesh) mesh = viewer.scene.getMeshByName(config.mesh);
        if (config?.material) {
            material = viewer.scene.getMaterialByName(config.material);
            // console.log(key, material)
            material.unfreeze();

            if (config?.albedoTexture) {
                material.albedoTexture = viewer.textures[key + 'albedo'];
                if (config?.albedoTextureHasAlpha)
                    material.albedoTextureHasAlpha = config.albedoTextureHasAlpha;
                if (config?.useAlphaFromAlbedoTexture)
                    material.useAlphaFromAlbedoTexture = config.useAlphaFromAlbedoTexture;
            }
            if (config?.opacityTexture) {
                // material.hasAlpha = true;
                // material.transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                material.opacityTexture = viewer.textures[key + 'opacity'];
                material.opacityTexture.vScale = -1;
            }
            if (config?.alpha) material.alpha = config.alpha;
            if (config?.transparencyMode) material.transparencyMode = config.transparencyMode;
            if (config?.albedoColor) material.albedoColor = config.albedoColor;
            if (config?.roughness) material.roughness = config.roughness;
            if (config?.indexOfRefraction) material.indexOfRefraction = config.indexOfRefraction;
            // if (config?.roughness) material.roughness = config.roughness;
            if (config?.bumpTexture) {
                material.bumpTexture = viewer.textures[key + 'bump'];
                material.bumpTexture.vScale = -1;
            }
            if (config?.lightmapTexture) {
                material.lightmapTexture = viewer.textures[key + 'lightmap'];
                material.lightmapTexture.vScale = -1;
            }
            if (config?.emissiveTexture) {
                material.emissiveTexture = viewer.textures[key + 'emissive'];
                material.emissiveTexture.vScale = -1;
            }
            if (config?.emissiveColor) material.emissiveColor = config.emissiveColor;
            if (config?.renderingGroupId) mesh.renderingGroupId = config.renderingGroupId;

            // material.freeze();
        }
    }

    // if (colorGradingLibrary[name] !== null) {
    //     colorGrading = viewer.textures['Lut'];
    //     viewer.scene.imageProcessingConfiguration.colorGradingTexture = colorGrading;
    //     viewer.scene.imageProcessingConfiguration.colorGradingTexture.level = 0.7;
    //     viewer.scene.imageProcessingConfiguration.colorGradingEnabled = true;
    // }

    return true;

    // let mesh: string;
    // let material: BABYLON.Material | BABYLON.PBRMaterial | any;
    // let albedoColor: BABYLON.Color3;
    // let roughness: number;
    // let indexOfRefraction: number;
    // let lightmapTexture: string;
    // let emissiveTexture: string;
    // let emissiveColor: BABYLON.Color3;



    // if (entry[0] === 'sphere') {
    //     viewer.models.categories.room[name].parts[entry[0]].material.emissiveTexture = viewer.textures[entry[0]];
    //     viewer.models.categories.room[name].parts[entry[0]].material.emissiveColor = BABYLON.Color3.White();
    // } else {
    //     viewer.models.categories.room[name].parts[entry[0]].material.lightmapTexture = viewer.textures[entry[0]];
    //     viewer.models.categories.room[name].parts[entry[0]].material.lightmapTexture.vScale = -1;
    //     viewer.models.categories.room[name].parts[entry[0]].material.albedoColor = BABYLON.Color3.Black();
    // }
    // room[name].parts[entry[0]].material.freeze();

    // return true;
}