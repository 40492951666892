import React, { useState } from "react";
import Logo from "../components/Logo";

const projects = [
  {
    title: "OwnXR Interactable Scenes",
    path: "https://ownxr.com/community",
    image: "assets/images/ownxr.svg",
  },
  {
    title: "DeltaV",
    path: "https://deltav-2023.web.app/",
    image: "assets/images/deltav.jpg",
  },
  {
    title: "Seddi Author",
    path: "https://seddi.com/products/",
    image: "assets/images/seddi.jpg",
  },
  {
    title: "Virtual Showroom",
    path: "https://canererdogan.tech/showroom",
    image: "assets/images/showroom.jpg",
  },
  {
    title: "Photorealistic Experience",
    path: "https://canererdogan.tech/configurator",
    image: "assets/images/photoreal.jpg",
  },
  {
    title: "3D Product Experience",
    path: "https://canererdogan.tech/product",
    image: "assets/images/product.jpg",
  },
  {
    title: "Mamut Expo Website",
    path: "https://mamutexpo.com",
    image: "assets/images/mamut.jpg",
  },
  {
    title: "Digital Catalog",
    path: "",
    image: "assets/images/catalog.jpg",
  },
  {
    title: "Screenwriting Application",
    path: "",
    image: "assets/images/screenwriting.jpg",
  },
];

function ProjectLink({ title, path, image }) {
  return (
    <a className="project" target={path && "_blank"} href={path || "#"}>
      <p>
        {title.toUpperCase()}
        {!path && (
          <>
            <br />
            <span>Coming soon...</span>
          </>
        )}
      </p>
      <img src={image} />
    </a>
  );
}

export default function Projects() {
  const [description, setDescription] = useState(
    "Click on icons for descriptions."
  );

  return (
    <div id="projects" className="section-content">
      <p>
        I made this web application using <Logo filename="babylon.png" />
        Babylon JS and <Logo filename="react.png" />
        React JS.
      </p>
      <p className="right">3D Experience and Augmented Reality Projects</p>
      <div className="carousel">
        {projects.map((product, i) => (
          <ProjectLink
            key={i}
            title={product.title}
            path={product.path}
            image={product.image}
          />
        ))}
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan={3}>Some languages and tools I use:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Logo filename="babylon.png" />
              Babylon JS
            </td>
            <td>
              <Logo filename="three.png" />
              Three.js
            </td>
            <td>
              <Logo filename="glsl.png" />
              GLSL
            </td>
            <td>
              <Logo filename="ar.png" />
              ARKit & ARCore
            </td>
            <td>
              <Logo filename="blender.png" />
              Blender
            </td>
            <td>
              <Logo filename="solidworks.png" />
              SolidWorks
            </td>
          </tr>
          <tr>
            <td>
              <Logo filename="next.png" />
              Next JS
            </td>
            <td>
              <Logo filename="react.png" />
              React JS
            </td>
            <td>
              <Logo filename="angular.png" />
              Angular JS
            </td>
            <td>
              <Logo filename="typescript.png" />
              TypeScript
            </td>
            <td>
              <Logo filename="javascript.jpg" />
              JavaScript
            </td>
          </tr>
          <tr>
            <td>
              <Logo filename="matlab.png" />
              MATLAB
            </td>
            <td>
              <Logo filename="python.png" />
              Python
            </td>
            <td>
              <Logo filename="tensorflow.png" />
              Tensorflow
            </td>
            <td>
              <Logo filename="adobe.png" />
              Adobe CC
            </td>
            <td>
              <Logo filename="protools.png" />
              Pro Tools
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
