import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";

const experiences = [
  {
    company: 'AVL Research & Engineering',
    title: 'Control Systems Engineer',
    time: 'Aug 2015 - Jan 2019',
    summary: 'Functional software research and development for automotive powertrain systems.',
    works: [
      'Transmission control unit software test environment design based on real-life scenarios,',
      'Functional development of complex software components involving sensor data compensations, physical adaptations, control actuations and functional safety,',
      'System modelling and control of transmission systems using machine learning,',
      'Model-in-the-loop (MiL), software-in-the-loop (SiL) and hardware-in-the-loop (HiL) tests,',
      'Electronic control unit flashing, monitoring and calibration over CAN,',
      'Data dictionary management, quality alignment and documentation,',
      'Software and general power flow design for dual-clutch, automatic and various hybrid transmissions for internal and external R&D projects,',
      'Transmission hydraulic circuit design,',
      'Daily communication and collaboration with colleagues abroad and local.',
    ]
  },
  {
    company: 'Ecemtag Control Technologies',
    title: 'Senior Control Systems Engineer',
    time: 'Feb 2019 - Aug 2020',
    summary: 'Functional software architecture design and development for automotive powertrain systems.',
    works: [
      'Transmission control unit software architecture design, from component to integrated software level,',
      'Requirement engineering and management in collaboration and communication with private customers and government institutions,',
      'Hands-on product level data dictionary, calibration and Model-in-the-loop (MiL) testing tool development,',
      'Software quality and guideline composition,',
      'Functional development of complex software components involving sensor data compensations, physical adaptations, control actuations and functional safety,',
      'Plant model development and validation,',
      'Data dictionary management, quality alignment and documentation,',
      'Model-in-the-loop (MiL), software-in-the-loop (SiL) and hardware-in-the-loop (HiL) test procedure composition,',
      'Daily communication and collaboration with colleagues abroad and local.',
    ]
  },
  {
    company: 'Orkvis',
    title: 'Senior Software Engineer',
    time: 'Dec 2020 - Jan 2022',
    summary: 'Frontend design and development for 3D web and AR experiences.',
    works: [
      'Structure development for branching advanced Babylon JS and Three JS projects that work concurrently with modern frontend frameworks such as React JS,',
      'Quality related contributions such as 3D naming and modeling conventions for interactive experiences,',
      'Full-responsive and WCAG compliant web pages design and implementation,',
      'App-free augmented Reality (AR) for iOS and Android, client-side model conversion for AR to save data traffic,',
      'Advanced asset management such as asynchronous imports, model partitioning, custom interaction management etc.,',
      'Creative scenery with animations, shaders, post-processing, annotations and more features,',
      'Projected engineering knowledge to experience design such as advanced algorithms for camera controls, automated scenery, experience state and transition control,',
      'Automated 3D web experiences in connection with customer specific dashboard and backend systems.',
    ]
  },
  {
    company: 'Seddi / Orion Innovation',
    title: 'Senior Software Engineer',
    time: 'Feb 2022 - Dec 2022',
    summary: 'Full stack online 2D & 3D textile composition, realistic simulation and rendering tool development.',
    works: [
      'Learn from and contribute to a much anticipated product in a well designed and maintained life cycle with a team of highly experienced academics, engineers, designers and managers from around the world,',
      'Extensive use of React JS, Redux, Node JS, Three JS and some Jest,',
      'UI development and styling from integrated design guides,',
      'Integration of, 3D interface with UI, backend with high-precision computers, and frontend functionalities,',
      'Workflow with Git, Jira and integrated code quality tools.',
    ]
  },
  {
    company: 'URoom / TEAM International',
    title: 'Lead Software Engineer',
    time: 'Jan 2023 - Present',
    summary: 'Software architecture design and development for web based 3D virtual experience creation tool.',
    works: [
      'Cross-platform Web3D application software architecture, implementation and maintenance,',
      'Extensive use of Babylon JS, namely: Loaders and serializers, user controls, collisions, rendering overlays and multiple layers, animation easing and blending, dynamic textures, transparent rendering, raycasting and more,',
      'Implementation of user controlled tools, namely: Transformation and snap, environment, lighting and shadows, 3D textbox, interaction and animation, UI and media controls,',
      'Automated asset processing on backend, workflow with Git, Azure and integrated code quality tools,',
      'Direct contribution to product development and feature planning.',
    ]
  },
];

let timelineRan = false;

export default function Experience() {
  const [expId, setExpId] = useState(0);

  const flash = useAnimation();
  useEffect(() => {
    setExpId(experiences.length - 1);
    if (timelineRan) {
      flash.start(t => ({ opacity: [0, 1] }));
      return;
    }
    let timeouts = experiences.map((exp, i) =>
      setTimeout(() => {
        setExpId(i);
        flash.start(t => ({ opacity: [0, 1] }));
        if (i === experiences.length - 1)
          timelineRan = true;
      }, i * 360));
    flash.start(t => ({ opacity: [0, 1] }));
    return () => timeouts.forEach(clearTimeout)
  }, []);

  return (
    <>
      <ul id="timeline">
        {experiences.map((exp, i) =>
          <li
            key={i}
            className={`timestamp${i === expId
              ? ' active'
              : (i + 1 === expId ? ' recent' : '')}`}
            onClick={() => {
              if (i !== expId)
                flash.start(t => ({ opacity: [0, 1] }));
              setExpId(i);
            }}
          />)}
      </ul>
      <motion.div
        id="experience"
        className="section-content"
        initial={{ opacity: 0 }}
        animate={flash}
      >
        <h2>{experiences[expId].company}</h2>
        <div className="secondary">
          <h3>{experiences[expId].title}</h3>
          <p>{experiences[expId].time}</p>
        </div>
        <p>{experiences[expId].summary}</p>
        <ul>
          {experiences[expId].works.map((work, i) =>
            <li key={i}>{work}</li>)}
        </ul>
      </motion.div>
    </>
  );
}