// @ts-nocheck

import { Engine } from "@babylonjs/core/Engines/engine";
import { settings } from "../user/settings";

export async function createEngine(device) {
  let engine;
  let antialias = !device.isMobile;

  if (!settings.engine.canvas) {
    settings.engine.canvas = document.getElementById("viewer");
    settings.document.canvas = document.getElementById("viewer");
  }

  engine = new Engine(
    settings.engine.canvas,
    antialias && settings.engine.antialias,
    settings.engine.options,
    settings.engine.adaptDeviceRatio
  );
  window.addEventListener("resize", (_) => {
    engine.resize();
  });
  return engine;
}
