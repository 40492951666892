import React from 'react';

export default function Logo({ filename }) {
  return <img
    className="logo"
    draggable={false}
    src={`assets/images/icons/${filename}`}
  />;
}

