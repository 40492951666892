// @ts-nocheck

import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Camera } from "@babylonjs/core/Cameras/camera";
import { createScene } from "./app/system/scene";
import { createEngine } from "./app/system/engine";
import { AuxConfig, setupAux } from "./app/system/auxiliaries";
import { setupModels } from "./app/component/model";
import { setupFonts } from "./app/component/annotation";
import { createCamera } from "./app/system/camera";
import { main } from "./app/user/main";
import { setupEnvironment } from "./app/component/environment";
import { setupTextures } from "./app/component/textures";
import { setupLoading } from "./app/user/loading";
import { detectDevice } from "./app/utility/device";
import { setupInterface } from "./app/user/interface";
import { assets } from "./app/user/settings";
import setupData from "./app/user/data";

export interface ViewerOptionConfig {
  verbose?: boolean;
  models?: Object;
  scene?: Object;
  camera?: Object;
  fonts?: Object;
  onload?: Function;
  rollback?: Function;
  metadata?: Object;
}

export interface ViewerConfig {
  engine: Engine;
  scene: Scene;
  device: Object;
  aux: AuxConfig;
  states: Object;
  models: any;
  camera: Camera;
  textures: Object;
  sprites: Object;
  loading: any;
  fonts: Object;
  ui: Object;
  metadata: Object;
  data: Object;
  onLoad: Function;
  onReady: Function;
  _loadTime: number;
  _options: ViewerOptionConfig;
}

export class Viewer {
  public engine: Engine;
  public scene: Scene;
  public environments: Object;
  public device: Object;
  public aux: AuxConfig;
  public states: Object;
  public models: any;
  public camera: Camera;
  public textures: Object;
  public sprites: Object;
  public loading: any;
  public fonts: Object;
  public ui: Object;
  public metadata: Object;
  public data: Object;

  public onLoad: Function;
  public onReady: Function;
  public onStart: Function;

  public _loadTime: number;
  public _options: ViewerOptionConfig;

  constructor(options?: ViewerOptionConfig) {
    if (options) {
      this._options = options;
      if (options?.verbose) {
        this._loadTime = performance.now();
      }
      if (options?.models) {
        for (const [key, model] of Object.entries(options.models))
          assets.models[key] = model;
      }

      if (options?.onLoad) this.onLoad = options.onLoad;
      if (options?.onStart) this.onStart = options.onStart;

      this.metadata = options?.metadata ? options.metadata : {};
      this.models = { categories: {} };
    } else {
      this._options = {};
    }

    this.init();
  }

  async init() {
    // try {

    this.data = setupData(this);
    this.device = await detectDevice();
    this.engine = await createEngine(this.device);
    this.fonts = await setupFonts(
      this._options?.fonts,
      this._options?.rollback
    );
    this.ui = setupInterface(this);
    this.loading = await setupLoading(this.engine, this.fonts, this.ui);
    this.scene = createScene(this.engine, this._options?.scene);
    this.camera = createCamera(this.scene, this._options?.camera);

    this.textures = {};
    this.sprites = {};
    this.aux = await setupAux(this.scene, (_) => {});

    this.environments = setupEnvironment(
      this.scene,
      this._options?.environment
    );
    this.textures = await setupTextures(this.scene, this.aux);
    await setupModels(
      this.scene,
      this.models,
      this.aux,
      async (_) => {
        this.loading.finish(async (_) => {
          this.onLoad && this.onLoad(this);
          this.onReady = await main(this);
          this.start();
        });
      },
      this.loading.progressFunction,
      this._options?.verbose
    );

    if (this._options?.verbose) {
      this._loadTime = performance.now() - this._loadTime;
      console.log(
        `Session loaded in ${(this._loadTime / 1000).toFixed(2)} seconds.`
      );
    }

    // } catch (error) {
    // this._options?.rollback && this._options.rollback(error);
    // }
  }

  start(): void {
    this.engine.runRenderLoop(() => {
      this.scene.render();
      if (this?.loading && !this.loading.isDisposed) this.loading.dispose();
    });
    this.scene.onReadyObservable.addOnce((_) => {
      this._options.verbose && console.log("SCENE READY");
      this.onReady && this.onReady();
      this?.onStart?.();
    });
  }
}
