// @ts-nocheck

import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import {Scene} from "@babylonjs/core/scene";
import {CubeTexture} from "@babylonjs/core/Materials/Textures/cubeTexture";
import {HDRCubeTexture} from "@babylonjs/core/Materials/Textures/hdrCubeTexture";
// import {
//     Scene,
//     HDRCubeTextureAssetTask, Texture,
//     BinaryFileAssetTask, CubeTexture, RawTexture, Engine} from 'babylonjs';
import {AuxConfig} from "../system/auxiliaries";
import {assets} from '../user/settings'

class Environment {
    name: string;
    texture: CubeTexture;
    intensity: number;
    isSkybox: boolean;

    constructor(
        name: string,
        filepath: string,
        scene: Scene,
        intensity?: number,
        rotation?: number,
        skybox?: boolean,
        level?: number
    ) {
        if (filepath.split('.').pop().toLowerCase() === 'hdr')
            this.texture = new HDRCubeTexture(filepath, scene, 1024);
        else this.texture = CubeTexture.CreateFromPrefilteredData(filepath, scene);
        // this.texture = CubeTexture.CreateFromPrefilteredData(filepath, scene);
        if (level !== undefined) this.texture.level = level;

        this.texture.rotationY = rotation === undefined ? 0 : rotation;
        this.intensity = intensity === undefined ? 1 : intensity;

        this.isSkybox = skybox;
    }

    set(scene: Scene) {
        scene.environmentTexture = this.texture;
        scene.environmentIntensity = this.intensity;
        this.isSkybox && scene.createDefaultSkybox(this.texture, false, 100, 2);
    }
}

export function setupEnvironment(scene: Scene, config?: Object) {
    let environments: Object = {};
    let isEnvironmentSet: boolean = false;

    for (let [key, parameters] of Object.entries(Object.assign(assets.environment, config))) {
        environments[key] = new Environment(
            key,
            parameters.filepath,
            scene,
            parameters?.intensity,
            parameters?.rotation,
            parameters?.skybox,
            parameters?.level
        );

        if (parameters?.default || Object.entries(assets.environment).length === 1) {
            environments[key].set(scene);
            isEnvironmentSet = true;
        }
    }

    if (!isEnvironmentSet) Object.values(environments)[0].set(scene);

    return environments;
}
