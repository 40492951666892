// @ts-nocheck

import {Viewer} from "../../viewer";

export default function setupData(viewer: Viewer) {
    class Data {

        constructor() {
            this.init();
        }

        init() {

        }
    }

    return new Data();
}